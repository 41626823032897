import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Container,
  Grid,
  Tabs,
  Tab,
  Divider,
  colors,
  makeStyles
} from "@material-ui/core";
import Page from "src/components/Page";
import ProfileDetails from "./ProfileDetails";
import ProfileSecurity from "./ProfileSecurity";
import CreateAvatar from "./CreateAvatar";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  inner: {
    width: theme.breakpoints.values.lg,
    maxWidth: "100%",
    margin: "0 auto",
    padding: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const Account = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page className={classes.root} title="Mi Perfil">
      <Container maxWidth={false}>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label="Perfil" />
          <Tab label="Seguridad" />
          <Tab label="Avatar" />
        </Tabs>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {value === 0 && <ProfileDetails />}
          {value === 1 && <ProfileSecurity />}
          {value === 2 && <CreateAvatar />}
        </div>
      </Container>
    </Page>
  );
};

export default Account;
