import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Menu,
  MenuItem,
  ListItemText
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
/* Services */
import TeacherService from "src/services/TeacherService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import Utils from "src/utils/utils";
import { useLazyQuery } from "@apollo/client";
import { LIST_STUDENTS_BY_CLASSGROUP_AND_ASSIGNMENT } from "src/services/queries";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  sortButton: {
    textTransform: "capitalize",
    letterSpacing: 0,
    marginRight: theme.spacing(2)
  }
}));

const PATH_URL = API_URL;

const AssignmentDetailList = ({ className, ...rest }) => {
  const classes = useStyles();
  const [assignments, setAssignments] = useState([]);
  const [totalAssignments, setTotalAssignments] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  /* Id classroom */
  let { classroomId, assignmentId } = useParams();
  const sortRef = useRef(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSort, setSelectedSort] = useState(
    SYSTEM_MESSAGES.ALPHABETIC_ORDER
  );

  const [assignmentList, resultAssignmentList] = useLazyQuery(
    LIST_STUDENTS_BY_CLASSGROUP_AND_ASSIGNMENT,
    {
      fetchPolicy: "no-cache",
      onCompleted: result => {
        console.log("RESULTADO: assignmentList", result);
        if (result) {
          if (result.listStudents) {
            setAssignments(result.listStudents);
            setTotalAssignments(result.listStudents.length);
          }
        }
      }
    }
  );

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, assignments.length - page * rowsPerPage);

  /**
   * handleSortOpen
   */
  const handleSortOpen = () => {
    setOpenSort(true);
  };

  /**
   * handleSortClose
   */
  const handleSortClose = () => {
    setOpenSort(false);
  };

  /**
   * handleSortSelect
   */
  const handleSortSelect = (value, items) => {
    setSelectedSort(value);
    setOpenSort(false);

    if (value == SYSTEM_MESSAGES.ALPHABETIC_ORDER) {
      items.sort((a, b) => a["nombre"].localeCompare(b["nombre"]));
    }

    if (value == SYSTEM_MESSAGES.MOST_RECENT) {
      items.sort((a, b) => {
        const aDate = a.fecha_agregado
          ? new Date(a.fecha_agregado)
          : new Date(a.fecha_agregado);
        const bDate = b.fecha_agregado
          ? new Date(b.fecha_agregado)
          : new Date(b.fecha_agregado);
        return bDate.getTime() - aDate.getTime();
      });
    }
  };

  useEffect(() => {
    console.log({
      group_id: parseInt(classroomId),
      assignment_id: parseInt(assignmentId)
    });
    assignmentList({
      variables: {
        group_id: parseInt(classroomId),
        assignment_id: parseInt(assignmentId)
      }
    });
  }, [assignmentId]);

  if (!assignments) return false;

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        action={
          <Box alignItems="center" display="flex">
            <Typography align="left" color="textPrimary" variant="h6">
              Mostrar por:
            </Typography>
            <Button
              className={classes.sortButton}
              onClick={handleSortOpen}
              ref={sortRef}
            >
              {selectedSort}
              <ArrowDropDownIcon />
            </Button>
            <Menu
              anchorEl={sortRef.current}
              className={classes.menu}
              onClose={handleSortClose}
              open={openSort}
            >
              {[
                SYSTEM_MESSAGES.MOST_RECENT,
                SYSTEM_MESSAGES.ALPHABETIC_ORDER
              ].map(option => (
                <MenuItem
                  className={classes.menuItem}
                  key={option}
                  onClick={() => handleSortSelect(option, assignments)}
                >
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Menu>
          </Box>
        }
        title={
          <Box display="flex">
            <Typography
              className={classes.card_header_title}
              variant={"h6"}
              gutterBottom
            >
              Estudiantes Asignados &nbsp;
            </Typography>
            <Typography
              className={classes.card_subtitle_total}
              variant={"h6"}
              gutterBottom
            >
              {totalAssignments} total
            </Typography>
          </Box>
        }
      />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Estudiante</TableCell>
                <TableCell>Fecha entregada</TableCell>
                <TableCell>Puntos</TableCell>
                <TableCell>Gemas</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assignments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(item => (
                  <TableRow hover key={item.id}>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Avatar className={classes.avatar_group}>
                          {item.avatar ? (
                            <img
                              className={classes.avatarItem}
                              src={`${PATH_URL}` + item.avatar}
                              onError={e => {
                                e.target.onerror = null;
                                e.target.src =
                                  process.env.PUBLIC_URL +
                                  "/static/images/avatars/neutral_avatar.jpeg";
                              }}
                            />
                          ) : (
                            <img
                              className={classes.avatarItem}
                              src={
                                process.env.PUBLIC_URL +
                                "/static/images/avatars/neutral_avatar.jpeg"
                              }
                            />
                          )}
                        </Avatar>
                        <Typography color="textPrimary" variant="body1">
                          {item.first_name} {item.last_name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {item.delivery_date
                        ? Utils.formatDateShort(item.delivery_date)
                        : ""}
                    </TableCell>
                    <TableCell>{item.points ? item.points : 0}</TableCell>
                    <TableCell>{item.gems ? item.gems : 0}</TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={assignments.length}
        onChangePage={handlePageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
      <Box display="flex" justifyContent="flex-end" p={2}></Box>
    </Card>
  );
};

AssignmentDetailList.propTypes = {
  className: PropTypes.string,
  assignments: PropTypes.array
};

AssignmentDetailList.defaultProps = {
  assignments: []
};

export default AssignmentDetailList;
