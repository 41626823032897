import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Link
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import Page from "src/components/Page";
/* Services */
import TeacherService from "src/services/TeacherService";
import { SIGNIN } from "src/services/mutations";
import { useMutation } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  logoImgk4k: {
    marginBottom: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 170,
    height: 120
  },
  k4kBoxLogin: {
    background: "#ffffff",
    padding: 35,
    borderRadius: 10,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const [errorMsg, setErrorMsg] = useState(errors);
  const [openAlert, setOpenAlert] = useState(false);

  const initialValues = {
    username: "",
    password: "",
    grant_type: "password"
  };
  const [user, setUser] = useState(initialValues);

  const [signinMutation, resultDigninMutation] = useMutation(SIGNIN, {
    onCompleted(data) {
      console.log(data);
      TeacherService.setUserData(data.signIn.token);
      navigate("/app/dashboard", { replace: true });
    },
    onError(error) {
      console.log(error);
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: "El usuario o la contraseña son incorrectos"
      });
      return navigate("/login", { replace: true });
    }
  });

  const handleChange = event => {
    setUser({
      ...user,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Page className={classes.root} title="Iniciar Sesión">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box textAlign="center">
            <img
              className={classes.logoImgk4k}
              src={process.env.PUBLIC_URL + "/static/images/logo-kulture.png"}
            />
          </Box>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              username: Yup.string()
                .email("Debe ser un correo electrónico válido")
                .required("El correo electrónico es obligatorio"),
              password: Yup.string().required("Se requiere una contraseña")
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                /**
                 * handlesubmitSignIn
                 */
                console.log({
                  login: values.username,
                  password: values.password
                });
                signinMutation({
                  variables: {
                    login: values.username,
                    password: values.password,
                    roleId: 2
                  }
                });

                /* TeacherService.login(values)
                  .then(response => {
                    if (response) {
                      let _postData = {
                        email: values.username
                      };
                      TeacherService.getCheckUserSession(_postData)
                        .then(response => {
                          if (response) {
                            if (response.data.docente === true) {
                              TeacherService.setUserData(response.data);
                              navigate("/app/dashboard", { replace: true });
                            } else {
                              setOpenAlert(true);
                              setErrorMsg({
                                type: "error",
                                message:
                                  "Asegurate de tener permisos para ingresar o contacta con el super administrador"
                              });
                              return navigate("/login", { replace: true });
                            }
                          } else {
                            setOpenAlert(false);
                            return navigate("/login", { replace: true });
                          }
                        })
                        .catch(err => {
                          return navigate("/login", { replace: true });
                        });
                    } else {
                      setOpenAlert(true);
                      setErrorMsg({
                        type: "error",
                        message: "El usuario o la contraseña son incorrectos"
                      });
                      return navigate("/login", { replace: true });
                    }
                  })
                  .catch(err => {
                    if (err.response.status === 400) {
                      setOpenAlert(true);
                      setErrorMsg({
                        type: "error",
                        message: "El usuario o la contraseña son incorrectos"
                      });
                    }
                    setUser(initialValues);
                    return navigate("/login", { replace: true });
                  }); */
              }, 500);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <Box className={classes.k4kBoxLogin}>
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                      Iniciar Sesión
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Iniciar sesión en la plataforma interna
                    </Typography>
                  </Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}></Grid>
                    <Grid item xs={12} md={6}></Grid>
                  </Grid>
                  <Box mt={3} mb={1}></Box>
                  <Collapse in={openAlert}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      El usuario o la contraseña son incorrectos
                    </Alert>
                  </Collapse>
                  <TextField
                    error={Boolean(touched.username && errors.username)}
                    fullWidth
                    helperText={touched.username && errors.username}
                    label="Correo electrónico"
                    margin="normal"
                    name="username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.username}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Contraseña"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Iniciar Sesión
                    </Button>
                  </Box>
                  <Box my={2} display="block">
                    <Link color="dark" href={"/resetpassword"}>
                      <Typography
                        color="textPrimary"
                        variant="h6"
                        align="center"
                      >
                        ¿Olvidó su contraseña?
                      </Typography>
                    </Link>
                  </Box>
                </form>
              </Box>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
