import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
/* Services */
import TeacherService from "src/services/TeacherService";
import { ME } from "src/services/queries";
import { useLazyQuery } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    height: "100%"
  },
  containerWelcome: {
    textAlign: "left",
    marginBottom: "20px"
  },
  welcomeMainText: {
    color: "#212529",
    fontSize: "24px"
  },
  subtitleWelcomeText: {
    color: "#000000",
    fontSize: "16px"
  }
}));

const WelcomeTeacher = ({ className, ...rest }) => {
  const classes = useStyles();
  /* teacher id */
  let teacherId = 45;
  const initialRecordState = {
    id: teacherId,
    nombre: "",
    apellido: "",
    correo: "",
    telefono: "",
    pais: "",
    ciudad: ""
  };

  const [me, resultMe] = useLazyQuery(ME);
  const [user, setUser] = useState([]);

  useEffect(() => {
    if (resultMe.data) {
      console.log(resultMe.data.me[0]);
      setUser(resultMe.data.me[0]);
    }
  }, [resultMe]);

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    const fetchTeacherDetail = () => {
      me();
    };

    fetchTeacherDetail();

    return () => {
      isLoaded = false;
    };
  }, []);

  if (!user) return false;

  return (
    <Grid className={clsx(classes.root, className)} {...rest}>
      <div className={classes.containerWelcome}>
        <Typography
          align="left"
          color="textPrimary"
          variant="body1"
          className={classes.welcomeMainText}
        >
          Hola {user.first_name} {user.last_name}!
        </Typography>
        <Typography
          align="left"
          color="textPrimary"
          variant="body1"
          className={classes.subtitleWelcomeText}
        >
          Estas son las novedades de tus Grupos de Clase.
        </Typography>
      </div>
    </Grid>
  );
};

WelcomeTeacher.propTypes = {
  className: PropTypes.string
};

export default WelcomeTeacher;
