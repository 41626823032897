import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@material-ui/core";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
/* Services */
import Utils from "src/utils/utils";
import AvatarUser from "src/components/AvatarUser";
import moment from "moment";
import { READ_NOTIFICATION } from "src/services/mutations";
import { useMutation } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  listItem: {
    "&:hover": {
      backgroundColor: theme.palette.background.default
    }
  },
  listItemUnReadIt: {
    backgroundColor: "#F1F1F1"
  },
  bgNotificationCircle: {
    background: "#FAFAFA !important"
  },
  avatarBlue: {
    backgroundImage: "#FAFAFA"
  },
  avatarGreen: {
    backgroundImage: "#FAFAFA"
  },
  avatarOrange: {
    backgroundImage: "#FAFAFA"
  },
  avatarIndigo: {
    backgroundImage: "#FAFAFA"
  },
  arrowForwardIcon: {
    color: "#9EA0A5",
    fontSize: "18px"
  },
  iconPassword: {
    color: "#0074BC"
  },
  iconSession: {
    color: "#EF3F40"
  },
  iconDefault: {
    color: "#0074BC",
    width: "25px",
    height: "25px"
  }
}));

const fromDate = date => {
  const duration = moment.duration(moment().diff(moment(date)));
  return duration.as("week") >= 1
    ? `${Math.floor(duration.as("week"))} semana(s)`
    : duration.as("day") >= 1
    ? `${Math.floor(duration.as("day"))} dia(s)`
    : duration.as("hour") >= 1
    ? `${Math.floor(duration.as("hour"))} hora(s)`
    : `${Math.floor(duration.as("minute"))} minuto(s)`;
};

const NotificationList = props => {
  const { notifications, className, onClose, ...rest } = props;

  const classes = useStyles();

  const [nMutation, resultNMutation] = useMutation(READ_NOTIFICATION, {
    onCompleted(data) {
      console.log("READ_NOTIFICATION", data);
      onClose(false);
    },
    onError(error) {
      console.log("READ_NOTIFICATION", error);
    }
  });

  const changeNotification = item => {
    const { id, link } = item;
    console.log("Notification ID:", id);

    nMutation({
      variables: {
        id
      }
    });
  };

  return (
    <List {...rest} className={clsx(classes.root, className)} disablePadding>
      {notifications.map((notification, i) => (
        <ListItem
          className={
            notification.readIt === 1
              ? classes.listItem
              : classes.listItemUnReadIt
          }
          component={RouterLink}
          divider={i < notifications.length - 1}
          key={notification.id}
          to={notification.link ? notification.link : ""}
          onClick={() => changeNotification(notification)}
        >
          <AvatarUser id={notification.userId} only />

          <ListItemText
            primary={
              <Typography type="body2" style={{ color: "#3A3B3F" }}>
                {notification.message}
              </Typography>
            }
            primaryTypographyProps={{ variant: "body1" }}
            secondary={
              <Typography
                type="body2"
                style={{ color: "#9EA0A5", fontSize: "12px" }}
              >
                {notification.createdAt ? fromDate(notification.createdAt) : ""}
              </Typography>
            }
          />
          {notification.link && (
            <ArrowForwardIosOutlinedIcon className={classes.arrowForwardIcon} />
          )}
        </ListItem>
      ))}
    </List>
  );
};

NotificationList.propTypes = {
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired
};

export default NotificationList;
