import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Collapse,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  IconButton,
  makeStyles,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { useLazyQuery, useMutation } from "@apollo/client";
import { ME, COUNTRIES } from "src/services/queries";
import { UPDATE_TEACHER } from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";
import { useSendNotification } from "src/services/notifications";

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  /* teacher id */
  let teacherId = 45;
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertDanger, setOpenAlertDanger] = useState(false);
  const initialRecordState = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    countries_id: "",
    provinces_id: ""
  };

  const [values, setValues] = useState(initialRecordState);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const [me, resultMe] = useLazyQuery(ME);
  useEffect(() => {
    if (resultMe.data) {
      console.log("GetMe:", resultMe.data.me[0]);
      setValues(resultMe.data.me[0]);
    }
  }, [resultMe]);

  const send = useSendNotification();
  const [addMutation, resultAddMutation] = useMutation(UPDATE_TEACHER, {
    onCompleted(data) {
      console.log("UPDATE TEACHER", data);
      setOpenAlert(true);

      const notificationParams = {
        userId: 1,
        message:
          "Perfil del docente " +
          values.first_name +
          " " +
          values.last_name +
          " actualizado.",
        link:
          "/admin/school/" +
          values.school_id +
          "/teacher/" +
          values.id +
          "/detail",
        allTeachers: false,
        allAdmins: true
      };
      send.sendNotification(notificationParams);
    },
    onError(error) {
      console.log(error);
    }
  });

  const [countries, setCountries] = useState([]);
  const [getCountries, resultcountries] = useLazyQuery(COUNTRIES, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: getCountries");
      console.log(result);

      if (result) {
        if (result.countries) {
          setCountries(result.countries);
        }
      }
    }
  });

  const fetchAllCountries = () => {
    getCountries();
  };

  /**
   * updateTeacher
   */
  const updateTeacher = values => {
    console.log({
      id: parseInt(values.id),
      email: values.email,
      phone: values.phone,
      first_name: values.first_name,
      last_name: values.last_name
      //countries_id: parseInt(values.countries_id),
      //provinces_id: parseInt(values.provinces_id)
    });
    addMutation({
      variables: {
        id: parseInt(values.id),
        email: values.email,
        phone: values.phone,
        first_name: values.first_name,
        last_name: values.last_name
        //countries_id: parseInt(values.countries_id),
        //provinces_id: parseInt(values.provinces_id)
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchAllCountries();

    const fetchTeacherDetail = () => {
      me();
    };

    fetchTeacherDetail();
  }, []);

  if (!values) return false;

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={values}
      validationSchema={Yup.object().shape({
        first_name: Yup.string().required("El nombre es requerido."),
        last_name: Yup.string().required("El apellido es requerido."),
        email: Yup.string().required("El correo electrónico es requerido."),
        phone: Yup.number()
          .typeError("Solo se permiten números.")
          .positive("Solo número positivo.")
          .integer("Solo se permiten enteros.")
          .required("El teléfono es requerido.")
        //provinces_id: Yup.string().required("La provincia es requerida."),
        //countries_id: Yup.string().required("El país es requerido.")
      })}
      onSubmit={values => {
        updateTeacher(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Información del perfil" />
            {resultAddMutation.loading && <LinearProgress />}
            <Divider />
            <CardContent>
              {/* Alert Success Delete | default hide */}
              <Collapse in={openAlert}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Tus datos de perfil se han actualizado correctamente.
                </Alert>
                <Box p={1}></Box>
              </Collapse>
              <Collapse in={openAlertDanger}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenAlertDanger(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Los campos no pueden estar vacios.
                </Alert>
                <Box p={1}></Box>
              </Collapse>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Nombre"
                    name="first_name"
                    error={Boolean(touched.first_name && errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.first_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Apellido"
                    name="last_name"
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.last_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Correo electrónico"
                    name="email"
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Teléfono"
                    name="phone"
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                {/*}
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Provincia"
                    name="provinces_id"
                    error={Boolean(touched.provinces_id && errors.provinces_id)}
                    helperText={touched.provinces_id && errors.provinces_id}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.provinces_id}
                    variant="outlined"
                  ></TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="País"
                    name="countries_id"
                    error={Boolean(touched.countries_id && errors.countries_id)}
                    helperText={touched.countries_id && errors.countries_id}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    select
                    value={values.countries_id}
                    variant="outlined"
                    SelectProps={{ native: true }}
                  >
                    <option key={""} value={""}></option>
                    {countries.map(option => (
                      <option key={option.code} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                  {*/}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={
                  isSubmitting && resultAddMutation.loading ? true : false
                }
              >
                GUARDAR
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
