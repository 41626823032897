/* Successful messages */
const MESSAGE_SUCCESS_CREATED = "Registro agregado correctamente.";
const MESSAGE_SUCCESS_UPDATED = "Datos actualizados correctamente.";
const MESSAGE_SUCCESS_DELETED = "El registro se ha eliminado correctamente.";
/* Error messages */
const MESSAGE_ERROR_CREATED = "Ocurrio un problema al momento de guardar el nuevo registro, favor de intentarlo más tarde.";
const MESSAGE_ERROR_UPDATED = "Ocurrio un problema al momento de actualizar los datos, favor de intentarlo más tarde.";
const MESSAGE_ERROR_DELETED = "El registro no pudo ser eliminado correctamente, favor de intentarlo más tarde.";
const MESSAGE_ERROR_PASSWORD_MATCH = "Las contraseñas no coinciden";
const MESSAGE_ERROR_IMAGE_EMPTY = "Seleccione una imagen";
const MESSAGE_ERROR_AUDIO_EMPTY = "Seleccione un archivo de audio";
const MESSAGE_ERROR_IMAGE_FORMAT_INVALID = "El archivo seleccionado no es valido";
/* Selection filters */
const MOST_RECENT = "Más Recientes";
const ALPHABETIC_ORDER = "Orden Alfabético";
const SCHOOL_GRADE = "Grado";
const SCHOOL_GROUP = "Grupo";

const SYSTEM_MESSAGES = {
    MESSAGE_SUCCESS_CREATED,
    MESSAGE_SUCCESS_UPDATED,
    MESSAGE_SUCCESS_DELETED,
    MESSAGE_ERROR_CREATED,
    MESSAGE_ERROR_UPDATED,
    MESSAGE_ERROR_DELETED,
    MESSAGE_ERROR_PASSWORD_MATCH,
    MESSAGE_ERROR_IMAGE_EMPTY,
    MESSAGE_ERROR_AUDIO_EMPTY,
    MESSAGE_ERROR_IMAGE_FORMAT_INVALID,
    MOST_RECENT,
    ALPHABETIC_ORDER,
    SCHOOL_GRADE,
    SCHOOL_GROUP
}

export default SYSTEM_MESSAGES