import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Collapse,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  IconButton,
  makeStyles,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import TeacherService from "src/services/TeacherService";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_TEACHER_PASSWORD } from "src/services/mutations";
import { ME } from "src/services/queries";

import * as Yup from "yup";
import { Formik } from "formik";
import { useSendNotification } from "src/services/notifications";

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileSecurity = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();
  const send = useSendNotification();

  /* teacher id */
  let teacherId = 45;
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertDanger, setOpenAlertDanger] = useState(false);
  const initialRecordState = {
    nueva_clave: "",
    confirmar_clave: ""
  };
  const [values, setValues] = useState(initialRecordState);
  const [teacher, setTeacher] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const [me, resultMe] = useLazyQuery(ME);
  useEffect(() => {
    if (resultMe.data) {
      console.log(resultMe.data.me[0]);
      setTeacher(resultMe.data.me[0]);
    }
  }, [resultMe]);

  useEffect(() => {
    me();
  }, []);

  const [updateMutation, resultUpdateMutation] = useMutation(
    UPDATE_TEACHER_PASSWORD,
    {
      onCompleted(data) {
        console.log("UPDATE_TEACHER_PASSWORD");
        console.log(data);
        setSubmitted(true);
        setOpenAlert(true);
        setValues(initialRecordState);

        formRef.current?.resetForm();

        /* const notificationParams = {
          userId: 1,
          message:
            "Cambio de contraseña del docente " +
            teacher.first_name +
            " exitoso.",
          link:
            "/admin/school/" +
            teacher.school_id +
            "/teacher/" +
            teacher.id +
            "/detail",
          allTeachers: false,
          allAdmins: true
        };
        send.sendNotification(notificationParams); */
      },
      onError(error) {
        console.log(error);
        setSubmitted(false);
        setOpenAlert(false);
        setOpenAlertDanger(false);
        setValues(initialRecordState);
      }
    }
  );

  /**
   * updatePassword
   */
  const updatePassword = values => {
    console.log(values);
    console.log(teacher);
    updateMutation({
      variables: {
        id: teacher.id,
        password: values.nueva_clave
      }
    });
  };

  if (!values && !teacher) return false;

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={initialRecordState}
      validationSchema={Yup.object().shape({
        nueva_clave: Yup.string()
          .required("La contraseña es requerida.")
          .min(
            8,
            "La contraseña es muy corta - Debería tener mínimo 8 caracteres."
          )
          .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Debe de contener mayúscula, número y al menos un caracter especial."
          ),
        confirmar_clave: Yup.string()
          .required("Confirmar la contraseña es requerido.")
          .min(
            8,
            "La contraseña es muy corta - Debería tener mínimo 8 caracteres."
          )
          .oneOf(
            [Yup.ref("nueva_clave"), null],
            "Las contraseñas no coinciden."
          )
          .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Debe de contener al menos una mayúscula, un número y un caracter especial."
          )
      })}
      onSubmit={values => {
        updatePassword(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Cambiar contraseña" />
            {resultUpdateMutation.loading && <LinearProgress />}
            <Divider />
            <CardContent>
              <Collapse in={openAlert}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Tu contraseña se ha actualizado correctamente.
                </Alert>
                <Box p={1}></Box>
              </Collapse>
              <Collapse in={openAlertDanger}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenAlertDanger(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  El campo contraseña y confirmar contraseña no pueden estar
                  vacios.
                </Alert>
                <Box p={1}></Box>
              </Collapse>
              <Grid container spacing={3}>
                <Grid item md={6} xs={6}>
                  <TextField
                    fullWidth
                    label="Contraseña"
                    name="nueva_clave"
                    type="password"
                    error={Boolean(touched.nueva_clave && errors.nueva_clave)}
                    helperText={touched.nueva_clave && errors.nueva_clave}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.nueva_clave}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <TextField
                    fullWidth
                    label="Confirmar contraseña"
                    name="confirmar_clave"
                    type="password"
                    error={Boolean(
                      touched.confirmar_clave && errors.confirmar_clave
                    )}
                    helperText={
                      touched.confirmar_clave && errors.confirmar_clave
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.confirmar_clave}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={
                  isSubmitting && resultUpdateMutation.loading ? true : false
                }
              >
                ACTUALIZAR
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

ProfileSecurity.propTypes = {
  className: PropTypes.string
};

export default ProfileSecurity;
