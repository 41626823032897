import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TeacherService from "src/services/TeacherService";
import { useMutation } from "@apollo/client";
import { ADD_STUDENT } from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles(() => ({
  root: {}
}));

const InviteStudent = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  /* Id classroom */
  let { classroomId } = useParams();
  const initialRecordState = {
    nombre: "",
    apellido: "",
    correo_estudiante: "",
    correo_tutor: ""
  };
  const [values, setValues] = useState(initialRecordState);
  const [submitted, setSubmitted] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [addMutation, resultAddMutation] = useMutation(ADD_STUDENT, {
    onCompleted(data) {
      console.log("STUDENT ADDED");
      setSubmitted(true);
      setOpenAlert(true);
      //setValues(initialRecordState);
      formRef.current?.resetForm();
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(false);
      //setValues(initialRecordState);
    }
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  /**
   * saveNewStudent
   */
  const saveNewStudent = values => {
    console.log({
      mentor: values.correo_tutor,
      email: values.correo_estudiante,
      groupId: classroomId,
      first_name: values.nombre,
      last_name: values.apellido
    });

    addMutation({
      variables: {
        mentor: values.correo_tutor,
        email: values.correo_estudiante,
        groupId: parseInt(classroomId),
        first_name: values.nombre,
        last_name: values.apellido
      }
    });
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={initialRecordState}
      validationSchema={Yup.object().shape({
        nombre: Yup.string().required("El nombre obra es requerido."),
        apellido: Yup.string().required("El apellido es requerido."),
        correo_estudiante: Yup.string().required(
          "El correo electrónico del estudiante es requerido."
        ),
        correo_tutor: Yup.string().required(
          "El correo electrónico del tutor es requerido"
        )
      })}
      onSubmit={values => {
        saveNewStudent(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Estudiante" />
            {resultAddMutation.loading && <LinearProgress />}
            <Divider />
            <CardContent>
              <Collapse in={openAlert}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Se ha enviado la invitación correctamente.
                </Alert>
                <Box p={1}></Box>
              </Collapse>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Nombre"
                    name="nombre"
                    error={Boolean(touched.nombre && errors.nombre)}
                    helperText={touched.nombre && errors.nombre}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.nombre}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Apellido"
                    name="apellido"
                    error={Boolean(touched.apellido && errors.apellido)}
                    helperText={touched.apellido && errors.apellido}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.apellido}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Correo del estudiante"
                    name="correo_estudiante"
                    error={Boolean(
                      touched.correo_estudiante && errors.correo_estudiante
                    )}
                    helperText={
                      touched.correo_estudiante && errors.correo_estudiante
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.correo_estudiante}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Correo del padre o tutor"
                    name="correo_tutor"
                    error={Boolean(touched.correo_tutor && errors.correo_tutor)}
                    helperText={touched.correo_tutor && errors.correo_tutor}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.correo_tutor}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={
                  isSubmitting && resultAddMutation.loading ? true : false
                }
              >
                ENVIAR INVITACIÓN
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

InviteStudent.propTypes = {
  className: PropTypes.string
};

export default InviteStudent;
