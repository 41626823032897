import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  makeStyles,
  colors
} from "@material-ui/core";
import ShowChartOutlinedIcon from "@material-ui/icons/ShowChartOutlined";
import { GET_PERCENT_BY_TEACHER } from "src/services/queries";
import { useLazyQuery } from "@apollo/client";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%"
  },
  avatar: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  }
}));

const TasksProgress = ({ className, ...rest }) => {
  const classes = useStyles();
  const initialRecordState = {
    asignaciones_completadas: 0
  };
  const [dashboard, setDashboard] = useState(initialRecordState);

  const [getTotal, resultTotal] = useLazyQuery(GET_PERCENT_BY_TEACHER, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: GET_PERCENT_BY_TEACHER", result);

      if (result && result.total_byteacher_assignment_percent) {
        setDashboard(result.total_byteacher_assignment_percent);
      }
    }
  });

  /**
   * Fetch API
   */
  useEffect(() => {
    getTotal();
  }, []);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              ASIGNACIONES COMPLETADAS
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {dashboard.percent ? dashboard.percent : 0}%
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <ShowChartOutlinedIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={3}>
          <LinearProgress
            value={dashboard.percent ? dashboard.percent : 0}
            variant="determinate"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

TasksProgress.propTypes = {
  className: PropTypes.string
};

export default TasksProgress;
