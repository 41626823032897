import { gql } from "@apollo/client";

export const SIGNIN = gql`
  mutation($login: String!, $password: String!, $roleId: Int) {
    signIn(login: $login, password: $password, roleId: $roleId) {
      token
    }
  }
`;

export const ADD_CLASS_GROUP = gql`
  mutation($groupId: Int!, $gradeId: Int!) {
    addClassGroupFromTeacher(groupId: $groupId, gradeId: $gradeId) {
      result
      message
    }
  }
`;

export const ADD_STUDENT = gql`
  mutation(
    $mentor: String!
    $email: String!
    $groupId: Int!
    $first_name: String!
    $last_name: String!
  ) {
    addStudent(
      mentor: $mentor
      email: $email
      groupId: $groupId
      first_name: $first_name
      last_name: $last_name
    ) {
      id
      mentor
      roleId
    }
  }
`;

export const UPDATE_STUDENT = gql`
  mutation(
    $mentor: String!
    $email: String!
    $password: String!
    $groupId: Int!
    $userId: Int!
  ) {
    updateStudent(
      mentor: $mentor
      email: $email
      password: $password
      groupId: $groupId
      userId: $userId
    ) {
      id
      mentor
      roleId
    }
  }
`;

export const ADD_ASSIGNMENT = gql`
  mutation(
    $artworks_id: Int!
    $level_difficulty_id: Int!
    $is_all: Int
    $userId: Int
    $groupId: Int
    $delivery_date: String
  ) {
    addAssignment(
      artworks_id: $artworks_id
      level_difficulty_id: $level_difficulty_id
      is_all: $is_all
      userId: $userId
      groupId: $groupId
      delivery_date: $delivery_date
    ) {
      id
      artworks_id
      level_difficulty_id
      is_all
      userId
      groupId
      delivery_date
    }
  }
`;

export const UPDATE_ASSIGNMENT = gql`
  mutation(
    $id: ID!
    $artworks_id: Int!
    $level_difficulty_id: Int!
    $is_all: Int
    $userId: Int
    $delivery_date: String
  ) {
    updateAssignment(
      id: $id
      artworks_id: $artworks_id
      level_difficulty_id: $level_difficulty_id
      is_all: $is_all
      userId: $userId
      delivery_date: $delivery_date
    ) {
      id
      artworks_id
      level_difficulty_id
      is_all
      userId
      delivery_date
    }
  }
`;

export const UPDATE_TEACHER = gql`
  mutation(
    $id: ID!
    $email: String!
    $password: String
    $phone: String!
    $first_name: String!
    $last_name: String!
    $countries_id: Int
    $provinces_id: Int
  ) {
    updateTeacher(
      id: $id
      email: $email
      password: $password
      phone: $phone
      first_name: $first_name
      last_name: $last_name
      countries_id: $countries_id
      provinces_id: $provinces_id
    )
  }
`;

export const UPDATE_TEACHER_PASSWORD = gql`
  mutation($id: ID!, $password: String) {
    updateTeacherPassword(id: $id, password: $password)
  }
`;

export const DELETE_STUDENT = gql`
  mutation($id: ID!) {
    deleteSchoolStudent(id: $id)
  }
`;

export const DELETE_ASSIGNMENT = gql`
  mutation($id: ID!) {
    deleteAssignment(id: $id)
  }
`;

export const CREATE_AD_IMPRESSION = gql`
  mutation($ads_id: Int!) {
    createAdImpressions(ads_id: $ads_id) {
      id
      user_ip
      ads_id
    }
  }
`;

export const CREATE_AD_CLICK = gql`
  mutation($ads_id: Int!) {
    createAdClick(ads_id: $ads_id) {
      id
      user_ip
      ads_id
    }
  }
`;

export const UPDATE_USER_AVATAR = gql`
  mutation($image: FileUpload!) {
    uploadUserAvatarImage(image: $image)
  }
`;

export const ACTIVE_ACCOUNT = gql`
  mutation($id: ID!, $code: String!) {
    activeAccount(id: $id, code: $code)
  }
`;

export const RESET_PASSWORD = gql`
  mutation($email: String!) {
    resetPassword(email: $email) {
      result
      forgotPasswordCode
    }
  }
`;

export const NEW_PASSWORD = gql`
  mutation($id: ID!, $code: String!, $password: String!) {
    newResetPassword(id: $id, code: $code, password: $password) {
      result
      forgotPasswordCode
    }
  }
`;

export const READ_NOTIFICATION = gql`
  mutation($id: ID!) {
    readNotification(id: $id) {
      id
      userId
      readIt
      message
      link
      createdAt
    }
  }
`;
