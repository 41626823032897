import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Grid, Box, Typography, makeStyles } from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import TeacherService from "src/services/TeacherService";
import { useLazyQuery } from "@apollo/client";
import { GET_GROUP_GRADE } from "src/services/queries";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {}
}));

const TopbarStudent = ({ className, ...rest }) => {
  const classes = useStyles();
  /* Id classroom */
  let { classroomId } = useParams();
  const initialRecordState = {
    nombre: "",
    codigo: "",
    total_estudiantes: 0
  };
  const [dashboard, setDashboard] = useState(initialRecordState);

  const [group, setGroup] = useLazyQuery(GET_GROUP_GRADE, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: GET_GROUP_GRADE", result);
      if (result && result.get_group_grade) {
        setDashboard(result.get_group_grade);
      }
    }
  });

  const fetchClassroomDetail = () => {
    group({
      variables: {
        group_id: classroomId
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchClassroomDetail();
  }, []);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-start">
            <Typography
              align="left"
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              {dashboard.group_name &&
                `${dashboard.group_name}: ${dashboard.grade_name}`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

TopbarStudent.propTypes = {
  className: PropTypes.string
};

export default TopbarStudent;
