import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  LinearProgress
} from "@material-ui/core";
import TeacherService from "src/services/TeacherService";
import Utils from "src/utils/utils";
import API_URL from "src/config/config";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { ASSIGNMENT_BY_ID } from "src/services/queries";
import AvatarUser from "src/components/AvatarUser";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    height: "100%"
  },
  avatar: {
    height: 64,
    width: 64,
    borderRadius: "10px"
  },
  avatarAssignment: {
    width: "64px",
    height: "64px",
    backgroundSize: "cover",
    objectFit: "cover",
    borderRadius: "10px"
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  }
}));

const PATH_URL = API_URL;

const AssignmentInfo = ({ className, ...rest }) => {
  const classes = useStyles();
  /* assignment id */
  let { assignmentId } = useParams();
  const initialRecordState = {
    avatar: "",
    nombre: "",
    dificultad: "",
    total_preguntas: 0,
    fecha_entrega: "",
    total_asignados: 0
  };
  const [dashboard, setDashboard] = useState([]);

  useEffect(() => {
    console.log("assignmentId =======", assignmentId);
    assignment({
      variables: {
        id: assignmentId
      }
    });
  }, [assignmentId]);

  const [assignment, resultAssignment] = useLazyQuery(ASSIGNMENT_BY_ID, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: assignment", result);
      if (result && result.assignment) setDashboard(result.assignment[0]);
    }
  });

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      {resultAssignment.loading && <LinearProgress />}
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textPrimary" gutterBottom variant="h3">
              {dashboard.name}
            </Typography>
          </Grid>
          <Grid item>
            {dashboard.artworks_id && <AvatarUser id={dashboard.artworks_id} />}
          </Grid>
        </Grid>
        <Box display="flex" alignItems="center">
          <Typography color="textSecondary" variant="caption">
            Dificultad:
          </Typography>
          &nbsp;
          <Typography color="textSecondary" variant="caption">
            {dashboard.level_name ? dashboard.level_name : ""}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography color="textSecondary" variant="caption">
            Preguntas:
          </Typography>
          &nbsp;
          <Typography color="textSecondary" variant="caption">
            {dashboard.quiz ? dashboard.quiz : 0}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography color="textSecondary" variant="caption">
            Asignada:
          </Typography>
          &nbsp;
          <Typography color="textSecondary" variant="caption">
            {moment(dashboard.delivery_date).format("DD/MM/YYYY")}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

AssignmentInfo.propTypes = {
  className: PropTypes.string
};

export default AssignmentInfo;
