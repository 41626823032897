import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from "@material-ui/core";
import NavItem from "./NavItem";
/* Services */
import TeacherService from "src/services/TeacherService";
import AvatarUser from "src/components/AvatarUser";
import { useLazyQuery } from "@apollo/client";
import { ME } from "src/services/queries";

const items = [
  {
    href: "/app/dashboard",
    icon: "/static/images/menu/ic_menu_dashboard.png",
    title: "Dashboard"
  },
  {
    href: "/teacher/classrooms",
    icon: "/static/images/menu/ic_menu_class_groups.png",
    title: "Grupos de Clase"
  },
  {
    href: "/teacher/classroom/new-classroom",
    icon: "/static/images/menu/ic_menu_class_groups.png",
    title: "Nuevo Grupo de Clase"
  },
  {
    href: "/teacher/account",
    icon: "/static/images/menu/ic_menu_account.png",
    title: "Mi Cuenta"
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)"
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const initialRecordState = null;
  const [user, setUser] = useState(initialRecordState);

  const [getMe, resultMe] = useLazyQuery(ME, {
    fetchPolicy: "no-cache"
    /* onCompleted: result => {
      console.log("RESULTADO: ME");
      console.log(result.me[0]);

      if (result) {
        if (result.me) {
          setUser(result.me[0]);
        }
      }
    } */
  });

  useEffect(() => {
    if (resultMe.data) {
      console.log("GetMe", resultMe.data.me[0]);
      setUser(resultMe.data.me[0]);
    }
  }, [resultMe]);

  useEffect(() => {
    getMe();
  }, []);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        {user && <AvatarUser only id={user.id} />}
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user && `${user.first_name} ${user.last_name}`}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
