import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Link
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import Page from "src/components/Page";
/* Services */
import TeacherService from "src/services/TeacherService";
import { SIGNIN, RESET_PASSWORD } from "src/services/mutations";
import { useMutation } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  logoImgk4k: {
    marginBottom: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 170,
    height: 120
  },
  k4kBoxLogin: {
    background: "#ffffff",
    padding: 35,
    borderRadius: 10,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
  }
}));

const ResetPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const errors = {
    type: "success",
    message: ""
  };
  const [errorMsg, setErrorMsg] = useState(errors);
  const [openAlert, setOpenAlert] = useState(false);

  const initialValues = {
    username: ""
  };
  const [user, setUser] = useState(initialValues);

  const [resetPasswordMutation, resultResetPassword] = useMutation(
    RESET_PASSWORD,
    {
      onCompleted(data) {
        console.log(data);
        setErrorMsg({
          type: "success",
          message: "Enviado. Revise su correo electrónico."
        });
        setOpenAlert(true);
      },
      onError(error) {
        console.log(error);
      }
    }
  );

  const handleChange = event => {
    setUser({
      ...user,
      [event.target.name]: event.target.value
    });
  };

  const backToLogin = () => {
    navigate("/login", { replace: true });
  };

  return (
    <Page className={classes.root} title="Recuperar contraseña">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box textAlign="center">
            <img
              className={classes.logoImgk4k}
              src={process.env.PUBLIC_URL + "/static/images/logo-kulture.png"}
            />
          </Box>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              username: Yup.string()
                .email("Debe ser un correo electrónico válido")
                .required("El correo electrónico es obligatorio")
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                console.log({
                  login: values.username,
                  password: values.password
                });
                resetPasswordMutation({
                  variables: {
                    email: values.username
                  }
                });
              }, 500);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <Box className={classes.k4kBoxLogin}>
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Typography color="textPrimary" variant="h2">
                      Reestablecer contraseña
                    </Typography>
                  </Box>

                  <Box mt={3} mb={1}></Box>
                  <Collapse in={openAlert}>
                    <Alert
                      severity={errorMsg.type}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {errorMsg.message}
                    </Alert>
                  </Collapse>
                  <TextField
                    error={Boolean(touched.username && errors.username)}
                    fullWidth
                    helperText={touched.username && errors.username}
                    label="Correo electrónico"
                    margin="normal"
                    name="username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.username}
                    variant="outlined"
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <Box my={2}>
                        <Button
                          color="success"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          onClick={backToLogin}
                        >
                          Regresar
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Box my={2}>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Enviar
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default ResetPassword;
