import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from "@material-ui/core";
import Page from "src/components/Page";
import { ACTIVE_ACCOUNT } from "src/services/mutations";
import { useMutation } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  logoImgk4k: {
    marginBottom: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 170,
    height: 120
  },
  k4kBoxLogin: {
    background: "#ffffff",
    padding: 35,
    borderRadius: 10,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    textAlign: "center"
  }
}));

const ActiveUser = () => {
  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");
  const id = new URLSearchParams(search).get("id");

  const classes = useStyles();
  const navigate = useNavigate();
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const [errorMsg, setErrorMsg] = useState(errors);
  const [openAlert, setOpenAlert] = useState(false);

  const initialValues = {
    username: "",
    password: "",
    grant_type: "password"
  };
  const [user, setUser] = useState(initialValues);

  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(true);

  const [activeMutation, resultActive] = useMutation(ACTIVE_ACCOUNT, {
    onCompleted(data) {
      console.log(data);
      if (data.activeAccount == true) setActive(true);
      else setActive(false);
      setLoading(false);
    },
    onError(error) {
      console.log(error);
      setActive(false);
      setLoading(false);
    }
  });

  const active_account_now = () => {
    console.log(id, code);
    if (!id || !code) return false;
    activeMutation({
      variables: {
        code,
        id: parseInt(id)
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      active_account_now();
    }, 2000);
  }, []);

  const goToLogin = () => {
    navigate("/login", { replace: true });
  };

  return (
    <Page className={classes.root} title="Iniciar Sesión">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box textAlign="center">
            <img
              className={classes.logoImgk4k}
              src={process.env.PUBLIC_URL + "/static/images/logo-kulture.png"}
            />
          </Box>
          <Box className={classes.k4kBoxLogin}>
            <Box mb={3}>
              <Typography
                color="textPrimary"
                variant="h1"
                align="center"
                gutterBottom
              >
                Activar Usuario
              </Typography>

              {loading ? (
                <Typography
                  color="textPrimary"
                  variant="h3"
                  align="center"
                  gutterBottom
                  style={{ color: "#999" }}
                >
                  Espere un momento...
                </Typography>
              ) : (
                <>
                  <Typography
                    color="textPrimary"
                    variant="h3"
                    align="center"
                    gutterBottom
                    style={{ color: "#999" }}
                  >
                    {active
                      ? `La cuenta ha sido activada!`
                      : `¡Hubo un error al activar la cuenta!`}
                  </Typography>

                  {active && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={goToLogin}
                    >
                      Iniciar Sesión
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default ActiveUser;
