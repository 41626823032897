import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  makeStyles
} from "@material-ui/core";
import ShowChartOutlinedIcon from "@material-ui/icons/ShowChartOutlined";
import { useLazyQuery } from "@apollo/client";
import { GET_PERCENT_BY_TEACHER_ASSIGNMENT_AND_GROUP } from "src/services/queries";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%"
  },
  avatar: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  }
}));

const TasksProgress = ({ className, ...rest }) => {
  const classes = useStyles();
  let { classroomId, assignmentId } = useParams();

  const initialRecordState = {
    total: 0,
    total_completed: 0,
    percent: 0
  };
  const [dashboard, setDashboard] = useState(initialRecordState);

  const [getTotal, resultTotal] = useLazyQuery(
    GET_PERCENT_BY_TEACHER_ASSIGNMENT_AND_GROUP,
    {
      fetchPolicy: "no-cache",
      onCompleted: result => {
        console.log(
          "RESULTADO: GET_PERCENT_BY_TEACHER_ASSIGNMENT_AND_GROUP",
          result
        );

        if (result && result.total_teacher_cg_assignment_percent) {
          setDashboard(result.total_teacher_cg_assignment_percent);
        }
      }
    }
  );

  useEffect(() => {
    getTotal({
      variables: {
        groupId: classroomId,
        assignmentId
      }
    });
  }, [assignmentId, classroomId]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              ASIGNACIÓN COMPLETA
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {dashboard.percent}%
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <ShowChartOutlinedIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Typography color="textSecondary" variant="caption" mt={2}>
            {dashboard.total_completed}/{dashboard.total}
          </Typography>
          <LinearProgress value={dashboard.percent} variant="determinate" />
        </Box>
      </CardContent>
    </Card>
  );
};

TasksProgress.propTypes = {
  className: PropTypes.string
};

export default TasksProgress;
