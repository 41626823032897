import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TeacherService from "src/services/TeacherService";
import SYSTEM_MESSAGES from "src/config/messages";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GRADES, GROUPS, ME } from "src/services/queries";
import { ADD_CLASS_GROUP } from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";
import { useSendNotification } from "src/services/notifications";

const useStyles = makeStyles(() => ({
  root: {}
}));

const NewGroupClass = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  const [grados, setGrados] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [turnos, setTurnos] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [selectGrado, setGradoSeleccionado] = useState({
    id: 0,
    grado: "Seleccione..."
  });
  const [selectGrupo, setGrupoSeleccionado] = useState({
    id: 0,
    grupo: "Seleccione..."
  });
  const [selectTurno, setTurnoSeleccionado] = useState({
    id: 0,
    turno: "Seleccione..."
  });
  const initialRecordState = {
    grado: "",
    grupo: ""
  };
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const [values, setValues] = useState(initialRecordState);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [me, setMe] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const send = useSendNotification();

  const [getMe, resultMe] = useLazyQuery(ME);
  useEffect(() => {
    if (resultMe.data) {
      console.log("GetMe data...:", resultMe.data.me[0]);
      setMe(resultMe.data.me[0]);
    }
  }, [resultMe]);

  const [addMutation, resultAddMutation] = useMutation(ADD_CLASS_GROUP, {
    onCompleted(data) {
      console.log("ADDED");
      setSubmitted(true);
      setOpenAlert(true);
      setValues(initialRecordState);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_CREATED
      });

      const notificationParams = {
        userId: 1,
        message: "Grupo de clase creado.",
        link: "/admin/school/" + me.school_id + "/teacher/" + me.id + "/detail",
        allTeachers: false,
        allAdmins: true
      };
      send.sendNotification(notificationParams);

      formRef.current?.resetForm();
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_CREATED
      });
      setValues(initialRecordState);
    }
  });

  const [getGroups, resultGroups] = useLazyQuery(GROUPS);
  useEffect(() => {
    if (resultGroups.data) {
      console.log(resultGroups.data.groups);
      setGrupos(resultGroups.data.groups);
    }
  }, [resultGroups]);

  const [getGrades, resultGrades] = useLazyQuery(GRADES);
  useEffect(() => {
    if (resultGrades.data) {
      console.log(resultGrades.data.grades);
      setGrados(resultGrades.data.grades);
    }
  }, [resultGrades]);

  const handleChangeGrado = event => {
    setGradoSeleccionado({ ...selectGrado, id: event.target.value });
  };

  const handleChangeGrupo = event => {
    setGrupoSeleccionado({ ...selectGrupo, id: event.target.value });
  };

  const handleChangeTurno = event => {
    setTurnoSeleccionado({ ...selectTurno, id: event.target.value });
  };

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  /**
   * saveNewClassroom
   */
  const saveNewClassroom = values => {
    console.log(values);

    if (values.grado == "" || values.grupo == "") {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: "Los campos no pueden estar vacíos"
      });
    } else {
      addMutation({
        variables: {
          groupId: parseInt(values.grupo),
          gradeId: parseInt(values.grado)
        }
      });
    }
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    /* Get Grados */
    const fetchAllGrados = () => {
      getGrades();
    };

    /* Get Grupos */
    const fetchAllGrupos = () => {
      getGroups();
    };

    /* Get Turnos */
    const fetchAllTurnos = () => {};

    fetchAllGrados();
    fetchAllGrupos();
    fetchAllTurnos();

    getMe();
  }, []);

  if (!grados && !grupos) return false;

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={initialRecordState}
      validationSchema={Yup.object().shape({
        grado: Yup.string().required("El grado es requerido."),
        grupo: Yup.string().required("El grupo es requerido.")
      })}
      onSubmit={values => {
        saveNewClassroom(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <Card>
          <CardHeader title="Grupo de Clase" />
          {resultAddMutation.loading && <LinearProgress />}
          <Divider />
          <CardContent>
            <Collapse in={openAlert}>
              <Alert
                severity={errorMsg.type}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {errorMsg.message}
              </Alert>
              <Box p={1}></Box>
            </Collapse>
            <form
              onSubmit={handleSubmit}
              autoComplete="off"
              className={clsx(classes.root, className)}
              {...rest}
              noValidate
            >
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Grado"
                    name="grado"
                    error={Boolean(touched.grado && errors.grado)}
                    helperText={touched.grado && errors.grado}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.grado}
                    variant="outlined"
                  >
                    <option key={""} value={""}></option>
                    {grados.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Grupo"
                    name="grupo"
                    error={Boolean(touched.grupo && errors.grupo)}
                    helperText={touched.grupo && errors.grupo}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.grupo}
                    variant="outlined"
                  >
                    <option key={""} value={""}></option>
                    {grupos.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Box my={2}>
                <Divider />
              </Box>
              <Box display="flex" justifyContent="flex-end" p={1}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={
                    isSubmitting && resultAddMutation.loading ? true : false
                  }
                >
                  CREAR
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

NewGroupClass.propTypes = {
  className: PropTypes.string
};

export default NewGroupClass;
