import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Container, Grid, colors, makeStyles } from "@material-ui/core";

import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_AD_CLICK, CREATE_AD_IMPRESSION } from "src/services/mutations";
import { useIntersection } from "src/utils/useIntersection";
import { GET_AD } from "src/services/queries";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    height: "100%"
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  },
  k4kContainerNoPadd: {
    marginLeft: "0px !important",
    marginRight: "0px !important"
  },
  imgAds: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    height: "100%"
  }
}));

const AdsFooter = ({ className, ...rest }) => {
  const classes = useStyles();

  const [ad1, setAd1] = useState([]);
  const [ad2, setAd2] = useState([]);
  const [loginSwiper1, setLoginSwiper1] = useState(null);
  const [loginSwiper2, setLoginSwiper2] = useState(null);
  const refAd1 = useRef();
  const inViewportAd1 = useIntersection(refAd1, "0px");
  const refAd2 = useRef();
  const inViewportAd2 = useIntersection(refAd2, "0px");

  const [addClick, resultAddClick] = useMutation(CREATE_AD_CLICK, {
    onCompleted(data) {
      console.log(data);
      if (data.createAdClick) {
        console.log("CREATE_AD_CLICK: ", data);
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  const [addImpression, resultAddImpression] = useMutation(
    CREATE_AD_IMPRESSION,
    {
      onCompleted(data) {
        console.log(data);
        if (data.createAdImpressions) {
          console.log("CREATE_AD_IMPRESSION: ", data);
        }
      },
      onError(error) {
        console.log(error);
      }
    }
  );

  const do_prev = () => {
    loginSwiper1.slidePrev();
  };

  const do_next = () => {
    loginSwiper1.slideNext();
  };

  useEffect(() => {
    if (ad1.length > 0) {
      Swiper.use([Navigation, Pagination, Autoplay]);

      let tempSwiper = new Swiper(".ad1-swiper", {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        autoplay: {
          delay: 8000
        },
        watchSlidesVisibility: true,
        on: {
          init: function(tempSwiper) {
            const index_currentSlide = tempSwiper.realIndex;
            const currentSlide = tempSwiper.slides[index_currentSlide];
            let dataID = currentSlide.getAttribute("data-id");

            if (inViewportAd1) {
              console.log("IMPRESSION AD 1 ADDED ON ----- INIT DATA.", {
                ads_id: parseInt(dataID)
              });
              addImpression({
                variables: {
                  ads_id: parseInt(dataID)
                }
              });
            }
          }
        }
      });
      setLoginSwiper1(tempSwiper);

      tempSwiper.on("slideChange", function() {
        const index_currentSlide = tempSwiper.realIndex;
        const currentSlide = tempSwiper.slides[index_currentSlide];
        let dataID = currentSlide.getAttribute("data-id");
        //console.log('slide changed data: ', currentSlide.getAttribute('data-id'));

        if (inViewportAd1) {
          console.log("IMPRESSION AD 1 ADDED.", {
            ads_id: parseInt(dataID)
          });
          addImpression({
            variables: {
              ads_id: parseInt(dataID)
            }
          });
        }
      });
    }
  }, [ad1]);

  useEffect(() => {
    if (ad2.length > 0) {
      Swiper.use([Navigation, Pagination, Autoplay]);

      let tempSwiper = new Swiper(".ad2-swiper", {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        autoplay: {
          delay: 8000
        },
        on: {
          init: function(tempSwiper) {
            const index_currentSlide = tempSwiper.realIndex;
            const currentSlide = tempSwiper.slides[index_currentSlide];
            let dataID = currentSlide.getAttribute("data-id");

            if (inViewportAd2) {
              console.log("IMPRESSION AD 2 ADDED. --- ON INIT", {
                ads_id: parseInt(dataID)
              });
              addImpression({
                variables: {
                  ads_id: parseInt(dataID)
                }
              });
            }
          }
        }
      });
      setLoginSwiper2(tempSwiper);

      tempSwiper.on("slideChange", function() {
        const index_currentSlide = tempSwiper.realIndex;
        const currentSlide = tempSwiper.slides[index_currentSlide];
        let dataID = currentSlide.getAttribute("data-id");
        //console.log('slide changed data: ', currentSlide.getAttribute('data-id'));

        if (inViewportAd2) {
          console.log("IMPRESSION AD 2 ADDED.", {
            ads_id: parseInt(dataID)
          });
          addImpression({
            variables: {
              ads_id: parseInt(dataID)
            }
          });
        }
      });
    }
  }, [ad2]);

  const [getAd1, resultAd1] = useLazyQuery(GET_AD, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      if (result) {
        if (result.ad && result.ad.length > 0) {
          console.log("RESULTADO: GET_AD 1", result.ad);
          setAd1(result.ad);
        }
      }
    }
  });

  const [getAd2, resultAd2] = useLazyQuery(GET_AD, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      if (result) {
        if (result.ad && result.ad.length > 0) {
          console.log("RESULTADO: GET_AD 2", result.ad);
          setAd2(result.ad);
        }
      }
    }
  });

  const getAllAds = () => {
    getAd1({
      variables: {
        position: 5
      }
    });

    getAd2({
      variables: {
        position: 6
      }
    });
  };

  const createAddClick = id => {
    console.log("createAddClick: ", id);
    addClick({
      variables: {
        ads_id: parseInt(id)
      }
    });
  };

  useEffect(() => {
    getAllAds();
  }, []);

  return (
    <Container maxWidth={false} className={classes.k4kContainerNoPadd}>
      <Grid container spacing={3}>
        <Grid item lg={6} sm={6} xl={6} xs={12} ref={refAd1}>
          {ad1 && ad1.length > 0 ? (
            <div className="swiper ad1-swiper slider swiper-container">
              <div className="swiper-wrapper">
                {ad1.map((item, index) => (
                  <div key={index} className="swiper-slide" data-id={item.id}>
                    <a
                      href={ad1.url}
                      onClick={() => createAddClick(item.id)}
                      target="blank"
                      className="text-decoration-none"
                    >
                      <img
                        className={classes.imgAds}
                        alt="K4K"
                        src={item.image}
                      />
                    </a>
                  </div>
                ))}
              </div>

              <div className="swiper-pagination"></div>
            </div>
          ) : null}
        </Grid>
        <Grid item lg={6} sm={6} xl={6} xs={12} ref={refAd2}>
          {ad2 && ad2.length > 0 ? (
            <div className="swiper ad2-swiper slider swiper-container">
              <div className="swiper-wrapper">
                {ad2.map((item, index) => (
                  <div key={index} className="swiper-slide" data-id={item.id}>
                    <a
                      href={ad2.url}
                      onClick={() => createAddClick(item.id)}
                      target="blank"
                      className="text-decoration-none"
                    >
                      <img
                        className={classes.imgAds}
                        alt="K4K"
                        src={item.image}
                      />
                    </a>
                  </div>
                ))}
              </div>

              <div className="swiper-pagination"></div>
            </div>
          ) : null}
        </Grid>
      </Grid>
    </Container>
  );
};

AdsFooter.propTypes = {
  className: PropTypes.string
};

export default AdsFooter;
