import React, { useEffect, useRef, useState } from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import WelcomeTeacher from "./WelcomeTeacher";
import Students from "./Students";
import ClassGroups from "./ClassGroups";
import TasksProgress from "./TasksProgress";
import AdsFooter from "./AdsFooter";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_AD, ME } from "src/services/queries";

import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { CREATE_AD_CLICK, CREATE_AD_IMPRESSION } from "src/services/mutations";
import { useIntersection } from "src/utils/useIntersection";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  imgAds: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    height: "100%"
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const [loginSwiper1, setLoginSwiper1] = useState(null);
  const [ad, setAd] = useState([]);

  const refAd = useRef();
  const inViewportAd = useIntersection(refAd, "0px");

  const [getAd, resultAd] = useLazyQuery(GET_AD, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      if (result) {
        if (result.ad && result.ad.length > 0) {
          console.log("RESULTADO: GET_AD #1", result.ad);
          setAd(result.ad);
        }
      }
    }
  });

  const [addClick, resultAddClick] = useMutation(CREATE_AD_CLICK, {
    onCompleted(data) {
      console.log(data);
      if (data.createAdClick) {
        console.log("CREATE_AD_CLICK: ", data);
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  const [addImpression, resultAddImpression] = useMutation(
    CREATE_AD_IMPRESSION,
    {
      onCompleted(data) {
        console.log(data);
        if (data.createAdImpressions) {
          console.log("CREATE_AD_IMPRESSION: ", data);
        }
      },
      onError(error) {
        console.log(error);
      }
    }
  );

  const createAddClick = id => {
    console.log("createAddClick: ", id);
    addClick({
      variables: {
        ads_id: parseInt(id)
      }
    });
  };

  useEffect(() => {
    if (ad.length > 0) {
      Swiper.use([Navigation, Pagination, Autoplay]);

      let tempSwiper = new Swiper(".ad-swiper", {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        watchSlidesVisibility: true,
        autoplay: {
          delay: 8000
        },
        on: {
          init: function(tempSwiper) {
            const index_currentSlide = tempSwiper.realIndex;
            const currentSlide = tempSwiper.slides[index_currentSlide];
            let dataID = currentSlide.getAttribute("data-id");

            console.log("IMPRESSION AD 1 ADDED ON ----- INIT DATA.", {
              ads_id: parseInt(dataID)
            });
            addImpression({
              variables: {
                ads_id: parseInt(dataID)
              }
            });
          }
        }
      });
      setLoginSwiper1(tempSwiper);

      tempSwiper.on("slideChange", function() {
        const index_currentSlide = tempSwiper.realIndex;
        const currentSlide = tempSwiper.slides[index_currentSlide];
        let dataID = currentSlide.getAttribute("data-id");
        //console.log('slide changed data: ', currentSlide.getAttribute('data-id'));

        console.log("IMPRESSION AD 1 ADDED.", {
          ads_id: parseInt(dataID)
        });
        addImpression({
          variables: {
            ads_id: parseInt(dataID)
          }
        });
      });
    }
  }, [ad]);

  useEffect(() => {
    getAd({
      variables: {
        position: 4
      }
    });
  }, []);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <WelcomeTeacher />
        <Grid container spacing={3}>
          <Grid item lg={4} sm={6} xl={3} xs={12}>
            <Students />
          </Grid>
          <Grid item lg={4} sm={6} xl={3} xs={12}>
            <TasksProgress />
          </Grid>
          <Grid item lg={4} sm={6} xl={3} xs={12} ref={refAd}>
            {ad && ad.length > 0 ? (
              <div className="swiper ad-swiper slider swiper-container">
                <div className="swiper-wrapper">
                  {ad.map((item, index) => (
                    <div key={index} className="swiper-slide" data-id={item.id}>
                      <a
                        href={ad.url}
                        onClick={() => createAddClick(item.id)}
                        target="blank"
                        className="text-decoration-none"
                      >
                        <img
                          src={item.image}
                          alt="Ads"
                          className={classes.imgAds}
                        />
                      </a>
                    </div>
                  ))}
                </div>

                <div className="swiper-pagination"></div>
              </div>
            ) : null}
          </Grid>
          <Grid item lg={12} md={12} xl={9} xs={12}>
            <ClassGroups />
          </Grid>
          <Grid item lg={12} md={12} xl={9} xs={12}>
            <AdsFooter />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
