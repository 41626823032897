import React, { useState, useEffect, useCallback, Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Collapse,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  IconButton,
  makeStyles,
  Typography,
  colors,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import TeacherService from "src/services/TeacherService";
import API_URL from "src/config/config";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ME, COUNTRIES } from "src/services/queries";
import { UPDATE_TEACHER, UPDATE_USER_AVATAR } from "src/services/mutations";
import Utils from "src/utils/utils";
import bytesToSize from "src/utils/bytesToSize";
import SYSTEM_MESSAGES from "src/config/messages";
import AvatarUser from "src/components/AvatarUser";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  dropZone: {
    border: "1px dashed rgba(0, 0, 0, 0.32)",
    padding: "5px",
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: "pointer"
    },
    width: "100%",
    height: "258px"
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 60,
    borderRadius: 500
  },
  info: {
    marginTop: theme.spacing(1),
    textAlign: "center"
  },
  list: {
    maxHeight: 320
  },
  actions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  },
  textLinkUpload: {
    color: "#0074BC"
  }
}));

const CreateAvatar = ({ className, ...rest }) => {
  const classes = useStyles();

  const errors = {
    type: "success",
    message: ""
  };

  const [files, setFiles] = useState([]);
  const [imageUpload, setImageUpload] = useState("");
  const [errorMsg, setErrorMsg] = useState(errors);

  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertDanger, setOpenAlertDanger] = useState(false);

  const [values, setValues] = useState([]);

  const [getMe, resultMe] = useLazyQuery(ME);
  useEffect(() => {
    if (resultMe.data) {
      console.log(resultMe.data.me[0]);
      setValues(resultMe.data.me[0]);
    }
  }, [resultMe]);

  useEffect(() => {
    getMe();
  }, []);

  const [addMutation, resultAddMutation] = useMutation(UPDATE_USER_AVATAR, {
    onCompleted(data) {
      console.log("UPDATE_USER_AVATAR ADDED");
      console.log(data);
      setOpenAlert(true);
      setOpenAlertDanger(false);
    },
    onError(error) {
      console.log(error);
      setOpenAlert(false);
      setOpenAlertDanger(true);
    }
  });

  const [countries, setCountries] = useState([]);
  const [getCountries, resultcountries] = useLazyQuery(COUNTRIES, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: getCountries");
      console.log(result);

      if (result) {
        if (result.countries) {
          setCountries(result.countries);
        }
      }
    }
  });

  const fetchAllCountries = () => {
    getCountries();
  };

  /**
   * updateAvatar
   */
  const updateAvatar = () => {
    console.log(imageUpload);

    if (imageUpload.length === 0) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_EMPTY
      });
      return;
    }

    if (imageUpload) {
      addMutation({
        variables: {
          image: imageUpload
        }
      });
    }
  };

  const UploadAvatar = props => {
    const { title } = props;

    const handleDrop = useCallback(acceptedFiles => {
      const file = acceptedFiles.find(f => f);

      setFiles(acceptedFiles);
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {};

      if (!Utils.validateFileImage(file)) {
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_FORMAT_INVALID
        });
        handleRemoveAll();
      } else {
        setImageUpload(file);
      }
    }, []);

    const handleRemoveAll = () => {
      setFiles([]);
      setImageUpload("");
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleDrop
    });

    return (
      <React.Fragment>
        <Card>
          <CardHeader title={`${title}`} />
          <Divider />
          <CardContent>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              className={clsx({
                [classes.dropZone]: true,
                [classes.dragActive]: isDragActive
              })}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div>
                {values && values.id && <AvatarUser id={values.id} only />}
              </div>
              <Typography gutterBottom variant="h3">
                Agregar archivo
              </Typography>
              <Typography
                className={classes.info}
                color="textSecondary"
                variant="body1"
              >
                Suelta los archivos aquí o haz clic en <br></br>{" "}
                <Link underline="always" className={classes.textLinkUpload}>
                  cargar
                </Link>{" "}
                desde tu dispositivo
              </Typography>
              <Typography gutterBottom variant="h6">
                Dimensiones: 512px x 512px
              </Typography>
              {files.length > 0 && (
                <Fragment>
                  {files.map((file, i) => (
                    <Typography
                      key={i}
                      className={classes.info}
                      color="textSecondary"
                      variant="body1"
                    >
                      {file.name} - {bytesToSize(file.size)}
                    </Typography>
                  ))}
                </Fragment>
              )}
            </Box>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  };

  if (!values) return false;

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader title="Actualizar Avatar" />
        {resultAddMutation.loading && <LinearProgress />}
        <Divider />
        <CardContent>
          {/* Alert Success Delete | default hide */}
          <Collapse in={openAlert}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Tus datos de perfil se han actualizado correctamente.
            </Alert>
            <Box p={1}></Box>
          </Collapse>
          <Collapse in={openAlertDanger}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlertDanger(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Los campos no pueden estar vacios.
            </Alert>
            <Box p={1}></Box>
          </Collapse>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <UploadAvatar title="Cargar imagen"></UploadAvatar>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" onClick={updateAvatar}>
            GUARDAR
          </Button>
        </Box>
      </Card>
    </form>
  );
};

CreateAvatar.propTypes = {
  className: PropTypes.string
};

export default CreateAvatar;
