/**
 * Kulture4Kids
 * Boombit - https://boombit.agency
 * Created at: 11/03/2021
 */
import axios from "axios";
import API_URL from "src/config/config";

/**
 * teacherHomeDashboard
 * @returns JSON Array
 */
function teacherHomeDashboard() {
  return axios.get(`${API_URL}` + "/api/teacher/dashboard/");
}

/**
 * getGroupsDefaultDashboard
 * @returns JSON Array
 */
function getGroupsDefaultDashboard() {
  return axios.get(`${API_URL}` + "/api/teacher/classrooms/");
}

/**
 * CLASSROOMS REQUEST
 */

/**
 * newClassRoom
 * [Create a new classroom]
 * @returns message create
 */
function newClassRoom(data) {
  return axios.post(`${API_URL}` + "/api/teacher/classrooms/", data);
}

/**
 * getGroupById
 * [Get a single group by id]
 * @param int id
 * @returns JSON Array
 */
function getGroupById(id) {
  return axios.get(`${API_URL}/api/teacher/classroom/${id}/`);
}

/**
 * editClassRoom
 * [Edit data a classroom]
 * @returns JSON Array
 */
function editClassRoom(id, data) {
  return axios.put(`${API_URL}/api/teacher/classroom/${id}/`, data);
}

/**
 * deleteClassRoom
 * [Delete data classroom]
 * @returns message delete
 */
function deleteClassRoom(id) {
  return axios.delete(`${API_URL}/api/teacher/classroom/${id}`);
}

/**
 * getDashboardByGroupId
 * [Get dashboard a single group by id]
 * @param int id
 * @returns JSON Array
 */
function getDashboardByGroupId(id) {
  return axios.get(`${API_URL}/api/teacher/classroom/${id}/dashboard`);
}

/**
 * STUDENTS REQUEST
 * [Classroom Students]
 */

/**
 * getAssignmentsByGroupId
 * [Get all assignements by group id]
 * @param int id
 * @returns JSON Array
 */
function getAssignmentsByGroupId(id) {
  return axios.get(`${API_URL}/api/teacher/classroom/${id}/assignments/`);
}

/**
 * getStudentsByGroupId
 * @param int id
 * @returns JSON Array
 */
function getStudentsByGroupId(id) {
  return axios.get(`${API_URL}/api/teacher/classroom/${id}/students`);
}

/**
 * getDashboardAssignmentById
 * @param int id
 * @returns JSON Array
 */
function getDashboardAssignmentById(id) {
  return axios.get(`${API_URL}/api/teacher/assignment/${id}/dashboard`);
}

/**
 * getStudentsByAssignmentId
 * @param int id
 * @returns JSON Array
 */
function getStudentsByAssignmentId(id) {
  return axios.get(`${API_URL}/api/teacher/assignment/${id}/students`);
}

/**
 * newAssignment
 * [Create a new assignment]
 * @returns message create
 */
function newAssignment(data) {
  return axios.post(`${API_URL}` + "/api/teacher/assignments/", data);
}

/**
 * getAssignmentsById
 * @param int id
 * @returns JSON Array
 */
function getAssignmentsById(id) {
  return axios.get(`${API_URL}/api/teacher/assignment/${id}`);
}

/**
 * editAssignment
 * [Edit data assignment]
 * @returns message update
 */
function editAssignment(id, data) {
  return axios.put(`${API_URL}/api/teacher/assignment/${id}/`, data);
}

/**
 * deleteAssignment
 * [Delete data assignment]
 * @returns message delete
 */
function deleteAssignment(id) {
  return axios.delete(`${API_URL}/api/teacher/assignment/${id}`);
}

/**
 * newStudent
 * [Create a new student]
 * @returns message create
 */
function newStudent(data) {
  return axios.post(`${API_URL}` + "/api/teacher/students/", data);
}

/**
 * getStudentById
 * @param int id
 * @returns JSON Array
 */
function getStudentById(id) {
  return axios.get(`${API_URL}/api/teacher/student/${id}`);
}

/**
 * editStudent
 * [Edit data student]
 * @returns message update
 */
function editStudent(id, data) {
  return axios.put(`${API_URL}/api/teacher/student/${id}/`, data);
}

/**
 * deleteStudent
 * [Delete data student]
 * @returns message delete
 */
function deleteStudent(id, data) {
  return axios.delete(`${API_URL}/api/teacher/student/${id}`);
}

/**
 * getAssignmentsByStudentId
 * @param int id
 * @returns JSON Array
 */
function getAssignmentsByStudentId(id) {
  return axios.get(`${API_URL}/api/teacher/student/${id}/assignments/`);
}

/**
 * getAssignmentsGroup
 * @param int id
 * @returns JSON Array
 */
function getAssignmentsGroup(id) {
  return axios.get(`${API_URL}/api/teacher/classroom/${id}/assignments`);
}

/**
 * PROFILE TEACHER REQUEST
 */

/**
 * getTeacherProfile
 * @returns JSON Array
 */
function getTeacherProfile(id) {
  return axios.get(`${API_URL}/api/teacher/${id}/profile/`);
}

/**
 * editTeacherProfile
 * [Teacher Update Profile]
 * @returns message update
 */
function editTeacherProfile(id, data) {
  return axios.put(`${API_URL}/api/teacher/${id}/profile/`, data);
}

/**
 * editTeacherPassword
 * [Teacher Update Password]
 * @returns message update
 */
function editTeacherPassword(id, data) {
  return axios.put(`${API_URL}/api/teacher/${id}/password/`, data);
}

/**
 * getGrados
 * @returns JSON Array
 */
function getGrados() {
  return axios.get(`${API_URL}/api/teacher/school/grades`);
}

/**
 * getGrupos
 * @returns JSON Array
 */
function getGrupos() {
  return axios.get(`${API_URL}/api/teacher/school/groups`);
}

/**
 * LISTINGS REQUEST
 */

/**
 * getTurnos
 * @returns JSON Array
 */
function getTurnos() {
  return axios.get(`${API_URL}/api/teacher/school/shifts`);
}

/**
 * getDifficulties
 * @returns JSON Array
 */
function getDifficulties() {
  return axios.get(`${API_URL}/api/teacher/school/difficulties`);
}

/**
 * getArtworks
 * @returns JSON Array
 */
function getArtworks() {
  return axios.get(`${API_URL}/api/teacher/art/artworks`);
}

/**
 * USER SIGN IN
 */

function login(data) {
  return axios.post(`${API_URL}` + "/auth/token", data);
}

/**
 * USER TOKEN
 * SingIn
 */

/**
 * setToken
 * @param {*} userToken
 */
function setToken(userToken) {
  sessionStorage.setItem("token", JSON.stringify(userToken));
}

function getToken() {
  const tokenString = sessionStorage.getItem("token");
  const userToken = JSON.parse(tokenString);

  return userToken?.token;
}

/**
 * setUserData
 * @param {*} userToken
 */
function setUserData(userToken) {
  sessionStorage.setItem("session", userToken);
}

/**
 * getUserID
 * [Get user id from storage]
 */
function getUserID() {
  const tokenString = sessionStorage.getItem("session");
  const userToken = JSON.parse(tokenString);

  return userToken?.id;
}

/**
 * System Notifications
 */

/**
 * getAllSystemNotifications
 * @returns JSON Array
 */
function getAllSystemNotifications() {
  return axios.get(`${API_URL}/api/teacher/notifications`);
}

/**
 * getCheckUserSession
 * @returns JSON Array
 */
function getCheckUserSession(data) {
  return axios.post(`${API_URL}/api/check-user-session/`, data);
}

const TeacherService = {
  teacherHomeDashboard,
  getGroupsDefaultDashboard,
  newClassRoom,
  getGroupById,
  editClassRoom,
  deleteClassRoom,
  getDashboardByGroupId,
  getAssignmentsByGroupId,
  getStudentsByGroupId,
  getDashboardAssignmentById,
  getStudentsByAssignmentId,
  newAssignment,
  getAssignmentsById,
  editAssignment,
  deleteAssignment,
  newStudent,
  getStudentById,
  editStudent,
  deleteStudent,
  getAssignmentsByStudentId,
  getTeacherProfile,
  editTeacherProfile,
  editTeacherPassword,
  getGrados,
  getGrupos,
  getTurnos,
  getDifficulties,
  getArtworks,
  login,
  setToken,
  getToken,
  setUserData,
  getUserID,
  getAllSystemNotifications,
  getCheckUserSession
};

export default TeacherService;
