import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Breadcrumbs,
  Typography,
  FormControl,
  MenuItem,
  Select
} from "@material-ui/core";
import Page from "src/components/Page";
import AssignmentInfo from "./AssignmentInfo";
import TaskProgress from "./TaskProgress";
import BestTime from "./BestTime";
import AssignmentDetailList from "./AssignmentDetailList";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  imgAds: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    height: "100%"
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  /* Id classroom */
  let { classroomId } = useParams();

  const handleClick = event => {
    event.preventDefault();
  };

  return (
    <Page className={classes.root} title="Detalle de la Asignación">
      <Container maxWidth={false}>
        <Box mb={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className={classes.text}
              to={"/teacher/classroom/view/" + `${classroomId}`}
            >
              Asignaciones
            </Link>
            <Typography color="textPrimary">
              Detalle de la asignación
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box mb={2}></Box>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <AssignmentInfo />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TaskProgress />
          </Grid>
          {/*}
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <BestTime/>
          </Grid>
  {*/}
          <Grid item lg={12} md={12} xl={9} xs={12}>
            <AssignmentDetailList />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
