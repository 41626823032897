import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import TeacherService from "src/services/TeacherService";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_STUDENT } from "src/services/mutations";
import { TEACHER_STUDENTS_LIST } from "src/services/queries";

const useStyles = makeStyles(() => ({
  root: {}
}));

const EditStudent = ({ className, ...rest }) => {
  const classes = useStyles();
  /* Id classroom */
  let { classroomId } = useParams();
  /* student id */
  let { studentId } = useParams();
  const initialRecordState = {
    nombre: "",
    apellido: "",
    correo_estudiante: "",
    correo_tutor: "",
    nueva_clave: "",
    confirmar_clave: "",
    classroom_id: classroomId
  };
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const [values, setValues] = useState(initialRecordState);
  const [submitted, setSubmitted] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState(errors);

  const [teacherStudentList, resultTeacherStudentList] = useLazyQuery(
    TEACHER_STUDENTS_LIST
  );
  useEffect(() => {
    if (resultTeacherStudentList.data) {
      console.log(resultTeacherStudentList.data.theTeacherStudent);
      setValues(resultTeacherStudentList.data.theTeacherStudent);
    }
  }, [resultTeacherStudentList]);

  const [addMutation, resultAddMutation] = useMutation(UPDATE_STUDENT, {
    onCompleted(data) {
      console.log("STUDENT UPDATED");
      setSubmitted(true);
      setOpenAlert(true);
      //setValues(initialRecordState);
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(false);
      //setValues(initialRecordState);
    }
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  /**
   * updateStudent
   */
  const updateStudent = () => {
    if (values.nueva_clave != values.confirmar_clave) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: "Las contraseñas deben coincidir."
      });
    } else {
      console.log({
        mentor: values.correo_tutor,
        email: values.correo_estudiante,
        password: values.nueva_clave,
        groupId: parseInt(classroomId),
        userId: parseInt(studentId)
      });

      addMutation({
        variables: {
          mentor: values.correo_tutor,
          email: values.correo_estudiante,
          password: values.nueva_clave,
          groupId: parseInt(classroomId),
          userId: parseInt(studentId)
        }
      });
    }
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    const fetchStudentDetail = () => {
      console.log({
        groupId: parseInt(classroomId),
        userId: parseInt(studentId)
      });
      teacherStudentList({
        variables: {
          groupId: parseInt(classroomId),
          userId: parseInt(studentId)
        }
      });
    };

    fetchStudentDetail();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader title="Estudiante" />
        <Divider />
        <CardContent>
          <Collapse in={openAlert}>
            <Alert
              severity={errorMsg.type}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {errorMsg.message}
            </Alert>
            <Box p={1}></Box>
          </Collapse>
          <Grid container spacing={3}>
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nombre"
                name="nombre"
                onChange={handleChange}
                required
                value={values.nombre}
                variant="outlined"
              />
            </Grid> */}
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Apellido"
                name="apellido"
                onChange={handleChange}
                required
                value={values.apellido}
                variant="outlined"
              />
            </Grid> */}
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Correo del estudiante"
                name="correo_estudiante"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Correo del padre o tutor"
                name="correo_tutor"
                onChange={handleChange}
                value={values.mentor}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Contraseña"
                name="nueva_clave"
                onChange={handleChange}
                required
                type="password"
                value={values.nueva_clave}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Confirmar contraseña"
                name="confirmar_clave"
                onChange={handleChange}
                required
                type="password"
                value={values.confirmar_clave}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" onClick={updateStudent}>
            GUARDAR CAMBIOS
          </Button>
        </Box>
      </Card>
    </form>
  );
};

EditStudent.propTypes = {
  className: PropTypes.string
};

export default EditStudent;
