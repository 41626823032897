import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
// import MainLayout from 'src/layouts/MainLayout';
import MainLayoutGuest from "src/layouts/MainLayoutGuest";
import AccountView from "src/views/account/AccountView";
import CustomerListView from "src/views/customer/CustomerListView";
import DashboardView from "src/views/reports/DashboardView";
import LoginView from "src/views/auth/LoginView";
import NotFoundView from "src/views/errors/NotFoundView";
import ProductListView from "src/views/product/ProductListView";
import RegisterView from "src/views/auth/RegisterView";
import SettingsView from "src/views/settings/SettingsView";
import ClassDetail from "src/views/groups/ClassDetail";
import Assignments from "src/views/groups/Assignments";
import EditAssignment from "src/views/groups/Assignments/EditAssignment";
import InviteStudent from "src/views/groups/Students";
import EditStudent from "src/views/groups/Students/EditStudent";
import DashboardGroups from "src/views/groups/DashboardGroups";
import ClassGroups from "src/views/groups/DashboardGroups/ClassGroups";
import NewGroupClass from "src/views/groups/NewGroups";
import AssignmentDetailList from "src/views/groups/Assignments/AssignmentDetail";
import ActiveUser from "./views/auth/ActiveUser";
import ResetPassword from "./views/auth/ResetPassword";
import NewPassword from "./views/auth/NewPassword";
import DashboardInit from "./layouts/DashboardInit";

const routes = [
  {
    path: "/",
    element: <DashboardInit />
  },
  {
    path: "app",
    element: <DashboardLayout />,
    children: [
      { path: "account", element: <AccountView /> },
      { path: "detail-student", element: <ClassDetail /> },
      { path: "dashboard", element: <DashboardView /> },
      { path: "products", element: <ProductListView /> },
      { path: "settings", element: <SettingsView /> },
      { path: "*", element: <Navigate to="/404" /> },
      { path: "detail", element: <ClassDetail /> }
    ]
  },
  {
    path: "teacher",
    element: <DashboardLayout />,
    children: [
      { path: "/classrooms", element: <ClassGroups /> },
      { path: "/classroom/view/:classroomId", element: <DashboardGroups /> },
      {
        path: "/classroom/view/:classroomId/:tab",
        element: <DashboardGroups />
      },
      { path: "/classroom/new-classroom", element: <NewGroupClass /> },
      {
        path: "/classroom/:classroomId/assignment/new-assignment",
        element: <Assignments />
      },
      {
        path: "/classroom/:classroomId/assignment/edit/:assignmentId",
        element: <EditAssignment />
      },
      {
        path: "/classroom/:classroomId/assignment/detail/:assignmentId",
        element: <AssignmentDetailList />
      },
      {
        path: "/classroom/:classroomId/student/invite",
        element: <InviteStudent />
      },
      {
        path: "/classroom/:classroomId/student/edit/:studentId",
        element: <EditStudent />
      },
      {
        path: "/classroom/:classroomId/student/detail/:studentId",
        element: <ClassDetail />
      },
      { path: "/account", element: <AccountView /> },
      { path: "404", element: <NotFoundView /> },
      { path: "*", element: <Navigate to="/404" /> }
    ]
  },
  {
    path: "/404",
    element: <NotFoundView />,
    children: [
      { path: "404", element: <NotFoundView /> },
      { path: "*", element: <Navigate to="/404" /> }
    ]
  }
];

const defaultRoute = [
  {
    path: "/",
    element: <MainLayoutGuest />,
    exact: true,
    children: [
      { path: "login", element: <LoginView /> },
      { path: "resetpassword", element: <ResetPassword /> },
      { path: "newpassword", element: <NewPassword /> },
      { path: "activeuser", element: <ActiveUser /> },
      { path: "*", element: <Navigate to="/login" /> }
    ]
  },
  {
    path: "404",
    element: <NotFoundView />,
    exact: true,
    children: [
      { path: "404", element: <NotFoundView /> },
      { path: "*", element: <Navigate to="/404" /> }
    ]
  }
];

const routing_app = {
  routes,
  defaultRoute
};

export default routing_app;
