import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  colors,
  makeStyles
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ShowChartOutlinedIcon from "@material-ui/icons/ShowChartOutlined";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import TeacherService from "src/services/TeacherService";
import API_URL from "src/config/config";
import AvatarUser from "src/components/AvatarUser";
import { useLazyQuery } from "@apollo/client";
import {
  GET_PG,
  GET_STUDENT_ASSIGNMENT_PERCENT,
  GET_USER,
  ME
} from "src/services/queries";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    height: "100%"
  },
  avatar: {
    backgroundColor: "#00B7BD",
    height: 56,
    width: 56
  },
  avatarProgress: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  },
  avatarItem: {
    width: "90px",
    height: "90px",
    backgroundSize: "cover",
    objectFit: "cover"
  }
}));

const PATH_URL = API_URL;

const StudentInfo = ({ className, ...rest }) => {
  const classes = useStyles();
  /* student id */
  let { studentId } = useParams();
  const initialRecordState = {
    avatar: "",
    nombre: "",
    apellido: "",
    correo: "",
    edad: 0,
    asignaciones_completadas: 0,
    total_asignaciones_completadas: 0,
    puntos: 0
  };
  const [dashboard, setDashboard] = useState(initialRecordState);
  const [points, setPoints] = useState(0);
  const [percent, setPercent] = useState(0);

  const [getMe, resultMe] = useLazyQuery(GET_USER);
  useEffect(() => {
    if (resultMe.data) {
      console.log(resultMe.data.user[0]);
      setDashboard(resultMe.data.user[0]);
    }
  }, [resultMe]);

  const [getPoints, resultGetPoints] = useLazyQuery(GET_PG, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: getPG", result);
      if (result && result.getPGByID) {
        setPoints(result.getPGByID);
      }
    }
  });

  const [getPercent, resultGetPercent] = useLazyQuery(
    GET_STUDENT_ASSIGNMENT_PERCENT,
    {
      fetchPolicy: "no-cache",
      onCompleted: result => {
        console.log("RESULTADO: getPercent", result);
        if (result && result.total_student_assignment_percent_by_id) {
          setPercent(result.total_student_assignment_percent_by_id);
        }
      }
    }
  );

  /**
   * Fetch API
   */
  useEffect(() => {
    getMe({
      variables: {
        id: studentId
      }
    });

    getPoints({
      variables: {
        userId: studentId
      }
    });

    getPercent({
      variables: {
        student_id: studentId
      }
    });
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h2">
                  {dashboard.first_name} {dashboard.last_name}
                </Typography>
              </Grid>
              <Grid item>
                <AvatarUser id={studentId} />
              </Grid>
            </Grid>
            <Box mt={0} display="flex" alignItems="center">
              <Typography color="textSecondary" variant="caption">
                {dashboard.email}
              </Typography>
            </Box>
            <Box mt={0} display="flex" alignItems="center">
              <Typography color="textSecondary" variant="caption">
                {dashboard.age ? dashboard.age : 0} años
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  ASIGNACIONES COMPLETADAS
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {percent.percent ? percent.percent : 0}%
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  <ShowChartOutlinedIcon />
                </Avatar>
              </Grid>
            </Grid>
            <Box mt={3}>
              <Typography color="textSecondary" variant="caption">
                {percent.total_completed ? percent.total_completed : 0} /{" "}
                {percent.total ? percent.total : 0}
              </Typography>
              <LinearProgress
                value={percent.percent ? percent.percent : 0}
                variant="determinate"
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  PUNTOS
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {points.points ? points.points : 0}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatarProgress}>
                  <StarBorderOutlinedIcon />
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

StudentInfo.propTypes = {
  className: PropTypes.string
};

export default StudentInfo;
