import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from "@material-ui/core";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import TeacherService from "src/services/TeacherService";
import { GET_STUDENTS_COUNT, ME } from "src/services/queries";
import { useLazyQuery } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    height: "100%"
  },
  avatar: {
    backgroundColor: "#00B7BD",
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

const Students = ({ className, ...rest }) => {
  const classes = useStyles();
  const initialRecordState = {
    total_estudiantes: 0,
    total_grupos: 0,
    asignaciones_completadas: 0
  };

  const [dashboard, setDashboard] = useState([]);
  const [me, resultMe] = useLazyQuery(ME);
  const [user, setUser] = useState([]);
  const [getStudentsCount, resultStudentsCount] = useLazyQuery(
    GET_STUDENTS_COUNT
  );

  useEffect(() => {
    if (resultStudentsCount.data) {
      setDashboard({
        students: resultStudentsCount.data.myStudentsCount,
        classGroups: resultStudentsCount.data.classGroupsCount
      });
    }
  }, [resultStudentsCount]);

  useEffect(() => {
    if (resultMe.data) {
      setUser(resultMe.data.me[0]);

      fetchTotalStudents(resultMe.data.me[0].id);
    }
  }, [resultMe]);

  const fetchTotalStudents = id => {
    getStudentsCount({
      variables: {
        id: parseInt(id)
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    me();

    return () => {
      isLoaded = false;
    };
  }, []);

  if (!dashboard) return false;

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              TOTAL DE ESTUDIANTES
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {dashboard.students ? dashboard.students : 0}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AccountCircleOutlinedIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={2} display="flex" alignItems="center">
          <Typography color="textSecondary" variant="caption">
            {dashboard.classGroups ? dashboard.classGroups : 0}
          </Typography>
          &nbsp;
          <Typography color="textSecondary" variant="caption">
            Grupos de Clase
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

Students.propTypes = {
  className: PropTypes.string
};

export default Students;
