import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Button,
  Box,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Modal,
  Typography,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  ListItemText,
  LinearProgress
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
/* Dialog */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
/* Services */
import TeacherService from "src/services/TeacherService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import Utils from "src/utils/utils";
import { LIST_ASSIGNMENTS_TEACHERS_BY_CLASSGROUP } from "src/services/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DELETE_ASSIGNMENT } from "src/services/mutations";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2),
    borderRadius: "10px"
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  contentDialog: {
    borderRadius: "10px !important"
  },
  actionsButtons: {
    alignItems: "center",
    justifyContent: "center !important"
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  sortButton: {
    textTransform: "capitalize",
    letterSpacing: 0,
    marginRight: theme.spacing(2)
  }
}));

const PATH_URL = API_URL;

const AssignmentsList = ({ className, ...rest }) => {
  const classes = useStyles();
  const [assignments, setAssignments] = useState([]);
  const [totalAssignments, setTotalAssignments] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const moreRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  // Open Modal Delete
  const [openModal, setOpenModal] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [itemSelected, setItemSelected] = useState({
    id: "",
    nombre: ""
  });
  /* Id classroom */
  let { classroomId } = useParams();
  const sortRef = useRef(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSort, setSelectedSort] = useState(
    SYSTEM_MESSAGES.ALPHABETIC_ORDER
  );

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const [assignmentList, resultAssignmentList] = useLazyQuery(
    LIST_ASSIGNMENTS_TEACHERS_BY_CLASSGROUP,
    {
      fetchPolicy: "no-cache",
      onCompleted: result => {
        console.log("RESULTADO: assignmentList", result);
        if (result) {
          if (result.listAssignments) {
            setAssignments(result.listAssignments);
            setTotalAssignments(result.listAssignments.length);
          }
        }
      }
    }
  );

  const [deleteMutation, resultDeleteMutation] = useMutation(
    DELETE_ASSIGNMENT,
    {
      onCompleted(data) {
        console.log("DELETE STUDENT");
        setOpenAlert(true);
        setAssignments(assignments.filter(item => item.id !== itemSelected.id));
        openCloseModalDelete();
      },
      onError(error) {
        console.log(error);
        setOpenAlert(false);
        openCloseModalDelete();
      }
    }
  );

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, assignments.length - page * rowsPerPage);

  /**
   * Menu Actions
   */
  const MenuMoreActions = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = val => {};

    const handleClick = e => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const { data } = props;

    return (
      <React.Fragment>
        <IconButton edge="end" size="small" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="card-actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleClose()}>
            <Link
              to={
                "/teacher/classroom/" +
                `${classroomId}` +
                "/assignment/edit/" +
                `${data.id}`
              }
            >
              Editar
            </Link>
          </MenuItem>
          <MenuItem onClick={() => selectRecordToDelete(data, "Eliminar")}>
            Eliminar
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  };

  const handleClickMenuActions = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenueActionsClose = () => {
    setAnchorEl(null);
  };

  /**
   * openCloseModalDelete
   */
  const openCloseModalDelete = () => {
    setModalEliminar(!modalEliminar);
  };

  const handleClose = () => {
    setOpenModal(false);
    setModalEliminar(false);
  };

  const selectRecordToDelete = (item, action) => {
    setOpenModal(true);
    setItemSelected(item);
    action === "Eliminar" ? openCloseModalDelete() : openCloseModalDelete();
  };

  /**
   * handleSortOpen
   */
  const handleSortOpen = () => {
    setOpenSort(true);
  };

  /**
   * handleSortClose
   */
  const handleSortClose = () => {
    setOpenSort(false);
  };

  /**
   * handleSortSelect
   */
  const handleSortSelect = (value, items) => {
    setSelectedSort(value);
    setOpenSort(false);

    if (value == SYSTEM_MESSAGES.ALPHABETIC_ORDER) {
      items.sort((a, b) => a["nombre"].localeCompare(b["nombre"]));
    }

    if (value == SYSTEM_MESSAGES.MOST_RECENT) {
      items.sort((a, b) => {
        const aDate = a.fecha_agregado
          ? new Date(a.fecha_agregado)
          : new Date(a.fecha_agregado);
        const bDate = b.fecha_agregado
          ? new Date(b.fecha_agregado)
          : new Date(b.fecha_agregado);
        return bDate.getTime() - aDate.getTime();
      });
    }
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    const fetchAssignments = () => {
      assignmentList({
        variables: {
          classGroup_id: parseInt(classroomId)
        }
      });
    };

    fetchAssignments();

    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * deleteAssignment
   */
  const deleteAssignment = id => {
    deleteMutation({
      variables: {
        id
      }
    });
    /* TeacherService.deleteAssignment(id)
      .then(response => {
        if (response) {
          setOpenAlert(true);
          setAssignments(
            assignments.filter(item => item.id !== itemSelected.id)
          );
          openCloseModalDelete();
        }
      })
      .catch(err => {
        setOpenAlert(false);
        openCloseModalDelete();
      }); */
  };

  /**
   * Modal Confirm Dialog
   */
  const bodyModalDialogConfirmDelete = (
    <div>
      <Dialog
        maxWidth={"sm"}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.contentDialog }}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            ¿Está usted seguro de que desea eliminar este registro:{" "}
            <b>{itemSelected && itemSelected.nombre}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionsButtons}>
          <Button onClick={() => openCloseModalDelete()} variant="outlined">
            CANCELAR
          </Button>
          <Button
            onClick={() => deleteAssignment(itemSelected && itemSelected.id)}
            variant="contained"
            color="primary"
          >
            ELIMINAR
          </Button>
        </DialogActions>
        <Box mb={2}></Box>
      </Dialog>
    </div>
  );

  if (!assignments) return false;

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      {/* Modal Delete */}
      <Modal open={modalEliminar} onClose={openCloseModalDelete}>
        {bodyModalDialogConfirmDelete}
      </Modal>
      <CardHeader
        action={
          <Box alignItems="center" display="flex">
            <Typography align="left" color="textPrimary" variant="h6">
              Mostrar por:
            </Typography>
            <Button
              className={classes.sortButton}
              onClick={handleSortOpen}
              ref={sortRef}
            >
              {selectedSort}
              <ArrowDropDownIcon />
            </Button>
            <Menu
              anchorEl={sortRef.current}
              className={classes.menu}
              onClose={handleSortClose}
              open={openSort}
            >
              {[
                SYSTEM_MESSAGES.MOST_RECENT,
                SYSTEM_MESSAGES.ALPHABETIC_ORDER
              ].map(option => (
                <MenuItem
                  className={classes.menuItem}
                  key={option}
                  onClick={() => handleSortSelect(option, assignments)}
                >
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Menu>
          </Box>
        }
        title={
          <Box display="flex">
            <Typography
              className={classes.card_header_title}
              variant={"h6"}
              gutterBottom
            >
              Asignaciones &nbsp;
            </Typography>
            <Typography
              className={classes.card_subtitle_total}
              variant={"h6"}
              gutterBottom
            >
              {totalAssignments} total
            </Typography>
          </Box>
        }
      />
      <Collapse in={openAlert}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          La asignación se ha eliminado correctamente.
        </Alert>
        <Box p={1}></Box>
      </Collapse>
      {resultAssignmentList.loading && <LinearProgress />}
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Asignación</TableCell>
                <TableCell>Fecha de entrega</TableCell>
                <TableCell>Asignadas</TableCell>
                <TableCell>Entregadas</TableCell>
                <TableCell>Pendientes</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assignments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(item => (
                  <TableRow hover key={item.id}>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Avatar className={classes.avatar_group}>
                          {item.image ? (
                            <img
                              className={classes.avatarItem}
                              src={item.image}
                              onError={e => {
                                e.target.onerror = null;
                                e.target.src =
                                  process.env.PUBLIC_URL +
                                  "/static/images/marilyn.png";
                              }}
                            />
                          ) : (
                            <img
                              className={classes.avatarItem}
                              src={
                                process.env.PUBLIC_URL +
                                "/static/images/marilyn.png"
                              }
                              alt=""
                            />
                          )}
                        </Avatar>
                        <Typography color="textPrimary" variant="body1">
                          <Link
                            className={classes.text}
                            to={
                              "/teacher/classroom/" +
                              `${classroomId}` +
                              "/assignment/detail/" +
                              `${item.id}`
                            }
                          >
                            {item.name}
                          </Link>
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {Utils.formatDateShort(item.delivery_date)}
                    </TableCell>
                    <TableCell>
                      {item.assignment ? item.assignment : 0}
                    </TableCell>
                    <TableCell>{item.delivery ? item.delivery : 0}</TableCell>
                    <TableCell>{item.pending ? item.pending : 0}</TableCell>
                    <TableCell>
                      <MenuMoreActions data={item} />
                      <Link
                        className={classes.text}
                        to={
                          "/teacher/classroom/" +
                          `${classroomId}` +
                          "/assignment/detail/" +
                          `${item.id}`
                        }
                      >
                        <IconButton edge="end" size="small">
                          <ArrowForwardIcon />
                        </IconButton>
                      </Link>
                      <Menu
                        id="menu-actions"
                        key={item.id}
                        anchorEl={anchorEl}
                        keepMounted
                        onClose={handleMenueActionsClose}
                        open={Boolean(anchorEl)}
                      >
                        <MenuItem>Editar</MenuItem>
                        <MenuItem>Eliminar</MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={assignments.length}
        onChangePage={handlePageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
      <Box display="flex" justifyContent="flex-end" p={2}></Box>
    </Card>
  );
};

AssignmentsList.propTypes = {
  className: PropTypes.string,
  assignments: PropTypes.array
};

AssignmentsList.defaultProps = {
  assignments: []
};

export default AssignmentsList;
