import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  ListItemText
} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
/* Services */
import TeacherService from "src/services/TeacherService";
import SYSTEM_MESSAGES from "src/config/messages";
import { CLASS_GROUPS_DASHBOARD } from "src/services/queries";
import { useLazyQuery } from "@apollo/client";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  sortButton: {
    textTransform: "capitalize",
    letterSpacing: 0,
    marginRight: theme.spacing(2)
  }
}));

const ClassGroups = ({ className, ...rest }) => {
  const classes = useStyles();
  const [groups, setGroups] = useState([]);
  const [totalClassGroups, setTotalClassGroups] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const sortRef = useRef(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSort, setSelectedSort] = useState(
    SYSTEM_MESSAGES.ALPHABETIC_ORDER
  );

  const [getGroups, resultGroups] = useLazyQuery(CLASS_GROUPS_DASHBOARD);
  useEffect(() => {
    if (resultGroups.data) {
      console.log(resultGroups.data.classGroupsDefaultDashboard);
      setGroups(resultGroups.data.classGroupsDefaultDashboard);
      setTotalClassGroups(resultGroups.data.classGroupsDefaultDashboard.length);
    }
  }, [resultGroups]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, groups.length - page * rowsPerPage);

  /**
   * handleSortOpen
   */
  const handleSortOpen = () => {
    setOpenSort(true);
  };

  /**
   * handleSortClose
   */
  const handleSortClose = () => {
    setOpenSort(false);
  };

  /**
   * handleSortSelect
   */
  const handleSortSelect = (value, items) => {
    setSelectedSort(value);
    setOpenSort(false);

    if (value === SYSTEM_MESSAGES.ALPHABETIC_ORDER) {
      console.log("Modo: alfabetico");
      setGroups(
        [...items].sort((a, b) =>
          a.grade_name.toLowerCase().localeCompare(b.grade_name.toLowerCase())
        )
      );
    }

    if (value === SYSTEM_MESSAGES.MOST_RECENT) {
      console.log("Modo: más reciente");
      setGroups(
        [...items].sort((a, b) => {
          if (a.createdAt > b.createdAt) return 1;
          else return -1;
        })
      );
    }
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    const fetchGroupsDashboard = () => {
      getGroups();
    };

    fetchGroupsDashboard();

    return () => {
      isLoaded = false;
    };
  }, []);

  if (!groups) return false;

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        action={
          <Box alignItems="center" display="flex">
            <Typography align="left" color="textPrimary" variant="h6">
              Mostrar por:
            </Typography>
            <Button
              className={classes.sortButton}
              onClick={handleSortOpen}
              ref={sortRef}
            >
              {selectedSort}
              <ArrowDropDownIcon />
            </Button>
            <Menu
              anchorEl={sortRef.current}
              className={classes.menu}
              onClose={handleSortClose}
              open={openSort}
            >
              {[
                SYSTEM_MESSAGES.MOST_RECENT,
                SYSTEM_MESSAGES.ALPHABETIC_ORDER
              ].map(option => (
                <MenuItem
                  className={classes.menuItem}
                  key={option}
                  onClick={() => handleSortSelect(option, groups)}
                >
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Menu>
          </Box>
        }
        title={
          <Box display="flex">
            <Typography
              className={classes.card_header_title}
              variant={"h6"}
              gutterBottom
            >
              Grupos de Clase &nbsp;
            </Typography>
            <Typography
              className={classes.card_subtitle_total}
              variant={"h6"}
              gutterBottom
            >
              {totalClassGroups} total
            </Typography>
          </Box>
        }
      />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Grupo</TableCell>
                <TableCell>Estudiantes</TableCell>
                <TableCell>Obras Asignadas</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groups
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(group => (
                  <TableRow hover key={group.id}>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Avatar className={classes.avatar_group}>
                          <GroupOutlinedIcon />
                        </Avatar>
                        <Typography color="textPrimary" variant="body1">
                          <Link
                            className={classes.text}
                            to={"/teacher/classroom/view/" + `${group.id}`}
                          >
                            {group.grade_name} : {group.group_name}
                          </Link>
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{group.students ? group.students : 0}</TableCell>
                    <TableCell>
                      {group.assignments ? group.assignments : 0}
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={groups.length}
        onChangePage={handlePageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
      <Box display="flex" justifyContent="flex-end" p={2}></Box>
    </Card>
  );
};

ClassGroups.propTypes = {
  className: PropTypes.string,
  groups: PropTypes.array
};

ClassGroups.defaultProps = {
  groups: []
};

export default ClassGroups;
