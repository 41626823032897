import { gql } from "@apollo/client";

export const GRADES = gql`
  query {
    grades {
      id
      name
      gradeId
      createdAt
    }
  }
`;

export const GROUPS = gql`
  query {
    groups {
      id
      name
      groupId
      createdAt
    }
  }
`;

export const CLASS_GROUPS_DASHBOARD = gql`
  query {
    classGroupsDefaultDashboard {
      id
      group_name
      grade_name
      students
      assignments
      createdAt
    }
  }
`;

export const GET_ARTWORKS = gql`
  query {
    artworks {
      id
      name
    }
  }
`;

export const LEVELS = gql`
  query {
    levels {
      id
      name
    }
  }
`;

export const ALL_STUDENTS = gql`
  query($groupId: Int!) {
    allStudents(groupId: $groupId) {
      id
      first_name
      last_name
    }
  }
`;

export const ME = gql`
  query {
    me: meCustom {
      id
      first_name
      last_name
      email
      phone
      provinces_id
      countries_id
      age
      school_id
    }
  }
`;

export const GET_STUDENTS_COUNT = gql`
  query($id: ID!) {
    myStudentsCount(id: $id)
    classGroupsCount(id: $id)
  }
`;

export const GET_ASSIGNMENTS_CLASSGROUP_LIST = gql`
  query {
    assignments_classgroup_list {
      id
      name
      artworks_id
      teacher_id
      level_difficulty_id
      is_all
      delivery_date
      assignment
      delivery
      pending
      overdue
    }
  }
`;

export const GET_STUDENTS_CLASSGROUP_LIST = gql`
  query($group_id: ID!) {
    students_classgroup_list(group_id: $group_id) {
      id
      userId
      first_name
      last_name
      assignment_count
      delivery
      pending
      overdue
      createdAt
    }
  }
`;

export const COUNTRIES = gql`
  query {
    countries {
      id
      code
      name
    }
  }
`;

export const TEACHER_STUDENTS_LIST = gql`
  query($groupId: Int!, $userId: Int!) {
    theTeacherStudent(groupId: $groupId, userId: $userId) {
      id
      school_id
      userId
      groupId
      createdAt
      email
      mentor
    }
  }
`;

export const LIST_ASSIGNMENTS_TEACHERS_BY_CLASSGROUP = gql`
  query($classGroup_id: ID!) {
    listAssignments: listAssignmentsTeachersByClassGroup(
      classGroup_id: $classGroup_id
    ) {
      id
      delivery_date
      userId
      first_name
      last_name
      assignment
      delivery
      pending
      overdue
      name
      image
      createdAt
    }
  }
`;

export const LIST_STUDENTS_BY_CLASSGROUP_AND_ASSIGNMENT = gql`
  query($group_id: ID!, $assignment_id: ID!) {
    listStudents: listStudentsByClassGroupAndAssignment(
      group_id: $group_id
      assignment_id: $assignment_id
    ) {
      id
      first_name
      last_name
      delivery_date
      time
      gems
      points
      quiz
      createdAt
    }
  }
`;

export const LIST_STUDENTS_BY_CLASSGROUP_AND_STUDENT = gql`
  query($group_id: ID!, $student_id: ID!) {
    listStudents: listStudentsByClassGroupAndStudent(
      group_id: $group_id
      student_id: $student_id
    ) {
      id
      first_name
      last_name
      level
      time
      gems
      points
      quiz
      artwork
      image
    }
  }
`;

export const GET_AD = gql`
  query($position: Int!) {
    ad: adPosition(position: $position) {
      id
      title
      start_date
      end_date
      image
      url
      mobile_image
      createdAt
      position
      weight
    }
  }
`;

export const GET_USER_AVATAR = gql`
  query($userId: Int!) {
    getAvatarById(userId: $userId) {
      id
      userId
      image
      active
      createdAt
      info
    }
  }
`;

export const GET_USER = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      first_name
      last_name
      email
      phone
      provinces_id
      countries_id
      age
    }
  }
`;

export const GET_PERCENT_BY_TEACHER = gql`
  query {
    total_byteacher_assignment_percent {
      total
      total_completed
      percent
    }
  }
`;

export const GET_PERCENT_BY_TEACHER_ASSIGNMENT_AND_GROUP = gql`
  query($groupId: ID!, $assignmentId: ID!) {
    total_teacher_cg_assignment_percent(
      groupId: $groupId
      assignmentId: $assignmentId
    ) {
      total
      total_completed
      percent
    }
  }
`;

export const GET_GROUP_GRADE = gql`
  query($group_id: ID!) {
    get_group_grade(group_id: $group_id) {
      id
      group_name
      grade_name
    }
  }
`;

export const VALIDATE_RESET_PASSWORD = gql`
  query($id: ID!, $code: String!) {
    validateResetPassword(id: $id, code: $code)
  }
`;

export const GET_NOTIFICATION = gql`
  query {
    notifications {
      id
      userId
      readIt
      message
      link
      createdAt
    }
  }
`;

export const GET_PG = gql`
  query($userId: ID!) {
    getPGByID(userId: $userId) {
      id
      userId
      points
      gems
      createdAt
    }
  }
`;

export const GET_STUDENT_ASSIGNMENT_PERCENT = gql`
  query($student_id: ID!) {
    total_student_assignment_percent_by_id(student_id: $student_id) {
      total
      total_completed
      percent
    }
  }
`;

export const ASSIGNMENT_BY_ID = gql`
  query($id: ID!) {
    assignment: assignments_by_id(id: $id) {
      id
      name
      quiz
      artworks_id
      level_name
      delivery_date
    }
  }
`;
