import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import TeacherService from "src/services/TeacherService";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ALL_STUDENTS, GET_ARTWORKS, LEVELS } from "src/services/queries";
import { UPDATE_ASSIGNMENT } from "src/services/mutations";

const useStyles = makeStyles(() => ({
  root: {}
}));

const EditAssignment = ({ className, ...rest }) => {
  const classes = useStyles();
  /* Id classroom */
  let { classroomId } = useParams();
  /* assignment id */
  let { assignmentId } = useParams();
  const [artworks, setArtworks] = useState([]);
  const [students, setStudents] = useState([]);
  const [difficulties, setDifficulties] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [selectObra, setObraSeleccionado] = useState({
    id: 0,
    nombre: "Seleccione..."
  });
  const [selectDificultad, setDificultadSeleccionado] = useState({
    id: 0,
    dificultad: "Seleccione..."
  });
  const [selectEstudiante, setEstudianteSeleccionado] = useState({
    id: 0,
    nombre: "Todos"
  });
  const initialRecordState = {
    obra: 0,
    asignar_a: 0,
    dificultad: 0,
    classroom_id: classroomId,
    fecha_entrega: new Date().toLocaleString()
  };
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const [values, setValues] = useState(initialRecordState);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState(errors);

  const [getArt, resultArt] = useLazyQuery(GET_ARTWORKS);
  useEffect(() => {
    if (resultArt.data) {
      console.log(resultArt.data.artworks);
      setArtworks(resultArt.data.artworks);
    }
  }, [resultArt]);

  const [getLevels, resultLevels] = useLazyQuery(LEVELS);
  useEffect(() => {
    if (resultLevels.data) {
      console.log("resultLevels.data.levels. . . . . .");
      console.log(resultLevels.data.levels);
      setDifficulties(resultLevels.data.levels);
    }
  }, [resultLevels]);

  const [getStudents, resultStudents] = useLazyQuery(ALL_STUDENTS);
  useEffect(() => {
    if (resultStudents.data) {
      console.log("resultStudents.data.levels. . . . . .");
      console.log(resultStudents.data.allStudents);
      setStudents(resultStudents.data.allStudents);
    }
  }, [resultStudents]);

  const [addMutation, resultAddMutation] = useMutation(UPDATE_ASSIGNMENT, {
    onCompleted(data) {
      console.log("ASSIGNMENT UPDATED");
      setSubmitted(true);
      setOpenAlert(true);
      //setValues(initialRecordState);
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(false);
      //setValues(initialRecordState);
    }
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  /**
   * updateAssignment
   */
  const updateAssignment = () => {
    /* let data = {
      obra: values.obra,
      dificultad: values.dificultad,
      asignar_a: values.asignar_a,
      classroom_id: classroomId,
      fecha_entrega:
        values.fecha_entrega +
        "T" +
        new Date().getHours() +
        ":" +
        new Date().getMinutes() +
        ":" +
        new Date().getSeconds() +
        "Z",
      user_id: values.user_id
    }; */

    console.log({
      id: assignmentId,
      artworks_id: parseInt(values.obra),
      level_difficulty_id: parseInt(values.dificultad),
      is_all: values.asignar_a == "all" ? 1 : 0,
      userId: values.asignar_a != "all" ? parseInt(values.asignar_a) : null,
      delivery_date:
        values.fecha_entrega +
        "T" +
        new Date().getHours() +
        ":" +
        new Date().getMinutes() +
        ":" +
        new Date().getSeconds() +
        "Z"
    });

    addMutation({
      variables: {
        id: assignmentId,
        artworks_id: parseInt(values.obra),
        level_difficulty_id: parseInt(values.dificultad),
        is_all: values.asignar_a == "all" ? 1 : 0,
        userId: values.asignar_a != "all" ? parseInt(values.asignar_a) : null,
        delivery_date: values.fecha_entrega
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    const getAssignmentsById = () => {
      TeacherService.getAssignmentsById(assignmentId)
        .then(response => {
          if (isLoaded) {
            const initialRecordState = {
              obra: response.data.obra,
              asignar_a: response.data.asignar_a,
              dificultad: response.data.dificultad,
              classroom_id: classroomId,
              fecha_entrega: new Date().toLocaleString()
            };
            setValues(initialRecordState);
          }
        })
        .catch(e => {
          setValues(initialRecordState);
        });
    };

    const fetchgArtworks = () => {
      getArt();
    };

    const fetchStudents = () => {
      getStudents();
    };

    const fetchDifficulties = () => {
      getLevels();
    };

    getAssignmentsById();
    fetchgArtworks();
    fetchStudents();
    fetchDifficulties();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader title="Asignación" />
        <Divider />
        <CardContent>
          <Collapse in={openAlert}>
            <Alert
              severity={errorMsg.type}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {errorMsg.message}
            </Alert>
            <Box p={1}></Box>
          </Collapse>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Obras"
                name="obra"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.obra}
                variant="outlined"
              >
                <option key={selectObra.id} value={selectObra.id}>
                  {selectObra.name}
                </option>
                {artworks.map(artwork => (
                  <option key={artwork.id} value={artwork.id}>
                    {artwork.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Asignar a"
                name="asignar_a"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.asignar_a}
                variant="outlined"
              >
                <option key={selectEstudiante.id} value={selectEstudiante.id}>
                  {selectEstudiante.nombre}
                </option>
                {students.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.first_name} {option.last_name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Dificultad"
                name="dificultad"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.dificultad}
                variant="outlined"
              >
                <option key={selectDificultad.id} value={selectDificultad.id}>
                  {selectDificultad.dificultad}
                </option>
                {difficulties.map(difficultie => (
                  <option key={difficultie.id} value={difficultie.id}>
                    {difficultie.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="fecha_entrega"
                label="Fecha de entrega"
                name="fecha_entrega"
                onChange={handleChange}
                type="date"
                value={values.fecha_entrega}
                InputLabelProps={{
                  shrink: true
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={updateAssignment}
          >
            GUARDAR
          </Button>
        </Box>
      </Card>
    </form>
  );
};

EditAssignment.propTypes = {
  className: PropTypes.string
};

export default EditAssignment;
