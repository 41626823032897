import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Link
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import Page from "src/components/Page";
/* Services */
import TeacherService from "src/services/TeacherService";
import { NEW_PASSWORD, SIGNIN } from "src/services/mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import { VALIDATE_RESET_PASSWORD } from "src/services/queries";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  logoImgk4k: {
    marginBottom: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 170,
    height: 120
  },
  k4kBoxLogin: {
    background: "#ffffff",
    padding: 35,
    borderRadius: 10,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
  }
}));

const NewPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");
  const id = new URLSearchParams(search).get("id");

  const [showResetPassword, setShowResetPassword] = useState(false);
  const [verify, setVerify] = useState(false);

  const errors = {
    type: "success",
    message: ""
  };
  const [errorMsg, setErrorMsg] = useState(errors);
  const [openAlert, setOpenAlert] = useState(false);

  const initialValues = {
    password: "",
    password_confirm: ""
  };
  const [user, setUser] = useState(initialValues);

  const [validate, resultValidate] = useLazyQuery(VALIDATE_RESET_PASSWORD, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      if (result && result.validateResetPassword) {
        console.log("RESULTADO: Validate", result.validateResetPassword);
        setVerify(result.validateResetPassword);
      }
    }
  });

  const [passwordMutation, resultPassword] = useMutation(NEW_PASSWORD, {
    onCompleted(data) {
      console.log(data);
      if (data.newResetPassword) {
        if (
          data.newResetPassword.result ||
          data.newResetPassword.result == "TRUE"
        ) {
          navigate("/login", { replace: true });
        }
      }
    },
    onError(error) {
      console.log(error);
    }
  });

  useEffect(() => {
    validate({
      variables: {
        code,
        id: parseInt(id)
      }
    });
  }, []);

  return (
    <Page className={classes.root} title="Restaurar contraseña">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box textAlign="center">
            <img
              className={classes.logoImgk4k}
              src={process.env.PUBLIC_URL + "/static/images/logo-kulture.png"}
            />
          </Box>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .required("La contraseña es requerida.")
                .min(
                  8,
                  "La contraseña es muy corta - Debería tener mínimo 8 caracteres."
                )
                .matches(
                  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                  "Debe de contener mayúscula, número y al menos un caracter especial."
                ),
              password_confirm: Yup.string()
                .required("Confirmar la contraseña es requerido.")
                .min(
                  8,
                  "La contraseña es muy corta - Debería tener mínimo 8 caracteres."
                )
                .oneOf(
                  [Yup.ref("password"), null],
                  "Las contraseñas no coinciden."
                )
                .matches(
                  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                  "Debe de contener al menos una mayúscula, un número y un caracter especial."
                )
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                console.log({
                  id,
                  code,
                  password: values.password
                });
                passwordMutation({
                  variables: {
                    id,
                    code,
                    password: values.password
                  }
                });
              }, 500);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <Box className={classes.k4kBoxLogin}>
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                      Restaurar contraseña
                    </Typography>
                  </Box>

                  <Collapse in={openAlert}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      El usuario o la contraseña son incorrectos
                    </Alert>
                  </Collapse>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Contraseña"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(
                      touched.password_confirm && errors.password_confirm
                    )}
                    fullWidth
                    helperText={
                      touched.password_confirm && errors.password_confirm
                    }
                    label="Confirmar contraseña"
                    margin="normal"
                    name="password_confirm"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password_confirm}
                    variant="outlined"
                  />
                  <Box my={2}>
                    {!verify ? (
                      <>
                        <Typography color="textPrimary" variant="h5">
                          No hay información válida para proceder.
                        </Typography>
                        <div className="mb-5"></div>
                      </>
                    ) : (
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Cambiar Contraseña
                      </Button>
                    )}
                  </Box>
                </form>
              </Box>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default NewPassword;
