import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TeacherService from "src/services/TeacherService";
import { ALL_STUDENTS, GET_ARTWORKS, LEVELS } from "src/services/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ADD_ASSIGNMENT } from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";
import { useSendNotification } from "src/services/notifications";
import { useMeProfile } from "src/services/me";

const useStyles = makeStyles(() => ({
  root: {}
}));

const NewAssignment = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();
  const user = useMeProfile();

  /* Id classroom */
  let { classroomId } = useParams();
  const [openError, setOpenError] = useState(false);
  const [artworks, setArtworks] = useState([]);
  const [students, setStudents] = useState([]);
  const [difficulties, setDifficulties] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [selectObra, setObraSeleccionado] = useState({
    id: 0,
    nombre: "Seleccione..."
  });
  const [selectDificultad, setDificultadSeleccionado] = useState({
    id: 0,
    dificultad: "Seleccione..."
  });
  const [selectEstudiante, setEstudianteSeleccionado] = useState({
    id: 0,
    nombre: "Todos"
  });
  const initialRecordState = {
    obra: "",
    asignar_a: "",
    dificultad: "",
    fecha_entrega: ""
  };
  const [values, setValues] = useState(initialRecordState);
  const [openAlert, setOpenAlert] = useState(false);

  const [getArt, resultArt] = useLazyQuery(GET_ARTWORKS);
  useEffect(() => {
    if (resultArt.data) {
      console.log(resultArt.data.artworks);
      setArtworks(resultArt.data.artworks);
    }
  }, [resultArt]);

  const [getLevels, resultLevels] = useLazyQuery(LEVELS);
  useEffect(() => {
    if (resultLevels.data) {
      console.log("resultLevels.data.levels. . . . . .");
      console.log(resultLevels.data.levels);
      setDifficulties(resultLevels.data.levels);
    }
  }, [resultLevels]);

  const [getStudents, resultStudents] = useLazyQuery(ALL_STUDENTS);
  useEffect(() => {
    if (resultStudents.data) {
      console.log("resultStudents.data.levels. . . . . .");
      console.log(resultStudents.data.allStudents);
      setStudents(resultStudents.data.allStudents);
    }
  }, [resultStudents]);

  const send = useSendNotification();
  const [addMutation, resultAddMutation] = useMutation(ADD_ASSIGNMENT, {
    onCompleted(data) {
      console.log("ASSIGNMENT ADDED", data);

      if (data.addAssignment) {
        setSubmitted(true);
        setOpenAlert(true);
        setOpenError(false);

        formRef.current?.resetForm();

        const notificationParams = {
          userId: 1,
          message: "Asignación creada.",
          link:
            "/admin/school/" +
            user[0].school_id +
            "/classgroup/" +
            classroomId +
            "/detail/2",
          allTeachers: false,
          allAdmins: true
        };
        send.sendNotification(notificationParams);
      } else {
        setSubmitted(false);
        setOpenAlert(false);
        setOpenError(true);
      }

      //setValues(initialRecordState);
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(false);
      //setValues(initialRecordState);
    }
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  /**
   * saveNewAssignment
   */
  const saveNewAssignment = values => {
    console.log({
      artworks_id: parseInt(values.obra),
      level_difficulty_id: parseInt(values.dificultad),
      is_all: values.asignar_a == "all" ? 1 : 0,
      userId: values.asignar_a != "all" ? parseInt(values.asignar_a) : null,
      delivery_date:
        values.fecha_entrega +
        "T" +
        new Date().getHours() +
        ":" +
        new Date().getMinutes() +
        ":" +
        new Date().getSeconds() +
        "Z",
      groupId: parseInt(classroomId)
    });

    addMutation({
      variables: {
        artworks_id: parseInt(values.obra),
        level_difficulty_id: parseInt(values.dificultad),
        is_all: values.asignar_a == "all" ? 1 : 0,
        userId: values.asignar_a != "all" ? parseInt(values.asignar_a) : null,
        delivery_date: values.fecha_entrega,
        groupId: parseInt(classroomId)
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    const fetchgArtworks = () => {
      getArt();
    };

    const fetchStudents = () => {
      getStudents({
        variables: {
          groupId: parseInt(classroomId)
        }
      });
    };

    const fetchDifficulties = () => {
      getLevels();
    };

    fetchgArtworks();
    fetchStudents();
    fetchDifficulties();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={initialRecordState}
      validationSchema={Yup.object().shape({
        obra: Yup.string().required("La obra es requerida."),
        asignar_a: Yup.string().required("Es necesario asignar un estudiante."),
        dificultad: Yup.string().required("La dificultad es requerida."),
        fecha_entrega: Yup.string().required(
          "La fecha de entrega es requerida."
        )
      })}
      onSubmit={values => {
        saveNewAssignment(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Asignación" />
            {resultAddMutation.loading && <LinearProgress />}
            <Divider />
            <CardContent>
              <Collapse in={openAlert}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  La nueva asignación se ha creado correctamente.
                </Alert>
                <Box p={1}></Box>
              </Collapse>

              <Collapse in={openError}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  No se pudo agregar la asignación. Vuelva a intentarlo.
                </Alert>
                <Box p={1}></Box>
              </Collapse>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Obras"
                    name="obra"
                    error={Boolean(touched.obra && errors.obra)}
                    helperText={touched.obra && errors.obra}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.obra}
                    variant="outlined"
                  >
                    <option key={""} value={""}></option>
                    {artworks.map(artwork => (
                      <option key={artwork.id} value={artwork.id}>
                        {artwork.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Asignar a"
                    name="asignar_a"
                    error={Boolean(touched.asignar_a && errors.asignar_a)}
                    helperText={touched.asignar_a && errors.asignar_a}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.asignar_a}
                    variant="outlined"
                  >
                    <option key={""} value={""}></option>
                    {students.length > 0 && (
                      <option key={"todos"} value={0}>
                        Todos {`(${students.length} estudiantes)`}
                      </option>
                    )}
                    {students.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.first_name} {option.last_name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Dificultad"
                    name="dificultad"
                    error={Boolean(touched.dificultad && errors.dificultad)}
                    helperText={touched.dificultad && errors.dificultad}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.dificultad}
                    variant="outlined"
                  >
                    <option key={""} value={""}></option>
                    {difficulties.map(difficultie => (
                      <option key={difficultie.id} value={difficultie.id}>
                        {difficultie.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    id="fecha_entrega"
                    label="Fecha de entrega"
                    name="fecha_entrega"
                    error={Boolean(
                      touched.fecha_entrega && errors.fecha_entrega
                    )}
                    helperText={touched.fecha_entrega && errors.fecha_entrega}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="date"
                    value={values.fecha_entrega}
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={
                  isSubmitting && resultAddMutation.loading ? true : false
                }
              >
                CREAR
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

NewAssignment.propTypes = {
  className: PropTypes.string
};

export default NewAssignment;
